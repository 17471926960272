<template>
  <section class="p-3">
    <loading-flux :value="loading"></loading-flux>
    <section class="d-flex flex-column flex-sm-row justify-content-between">
      <h2 class="ml-1 my-2">
        {{ title }}
      </h2>
      <v-breadcrumbs :items="breadcrumb"></v-breadcrumbs>
    </section>
    <v-row align-content="center" align-md="center" class="mt-1">
      <v-col cols="12" md="4" sm="12">
        <v-text-field
          v-model="filters.filter"
          clearable
          placeholder="Buscar"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" sm="12"> </v-col>
      <v-col cols="12" md="4" sm="12">
        <p class="my-0 text-right mt-3">
          Número de solicitudes total:
          {{ filteredApproveRequests.length }}
        </p>
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-3 px-5 py-3 mt-1"
      :headers="tableHeaders"
      :items="filteredApproveRequests"
      :items-per-page="20"
      :page.sync="filters.page"
      :loading="loading"
      loading-text="Cargando solicitudes"
      item-key="id"
      hide-default-footer
      no-data-text="Sin solicitudes"
      @page-count="pageCount = $event"
    >
      <template v-slot:item.created_at="{ item }">
        <span>
          {{ moment(item.created_at).format("DD/MM/YYYY") }}
        </span>
      </template>
      <template v-slot:item.amount="{ item }">
        <span>
          {{ "$ " + item.amount.toLocaleString("es", optionsFormatting) }}
        </span>
      </template>

      <template v-slot:item.sent_to_erp="{ item }">
        <v-chip :color="getColorStatus(item)" class="text-white">
          {{
            item.cancelled
              ? "Anulada"
              : item.rejected
              ? "Rechazada"
              : item.sent_to_erp
              ? "Aprobada"
              : "Pendiente"
          }}</v-chip
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-items-center justify-content-start">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openModalListMaterialsRequest(item)"
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>
            <span>Ver Detalles</span>
          </v-tooltip>
          <v-tooltip top v-if="permissionToApprove(item)">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="
                  approveOrRejectedRequestMaterial({
                    item,
                    action: true,
                  })
                "
                icon
                color="success"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-check
                </v-icon>
              </v-btn>
            </template>
            <span>Aprobar solicitud</span>
          </v-tooltip>
          <v-tooltip
            top
            v-if="
              (user &&
                item.created_by === user.email &&
                !item.cancelled &&
                !item.rejected &&
                !item.sent_to_erp) ||
                permissionToApprove(item)
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="cancelRequestApprove(item)"
                icon
                color="red darken-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
            <span>Anular Solicitud</span>
          </v-tooltip>
          <v-tooltip top v-if="permissionToApprove(item)">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="
                  approveOrRejectedRequestMaterial({
                    item,
                    action: false,
                  })
                "
                icon
                color="red"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-cancel
                </v-icon>
              </v-btn>
            </template>
            <span>Rechazar solicitud</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <RequestDetails
      @requestUpdated="$emit('requestUpdated')"
      @approverUpdated="$emit('approverUpdated')"
      ref="requestMaterialItems"
      :currentRequestMaterials="currentRequestMaterials"
      :modalAction="dialogListMaterialsRequest"
      :closeModalListMaterialsRequest="closeModalListMaterialsRequest"
    ></RequestDetails>
    <div id="listMaterialsSelected" style="display: none">
      <table-materials
        ref="approveOrRejectRequest"
        :materials="materialsToApproveOrReject"
      >
        <template v-slot:comment>
          <v-textarea
            outlined
            rows="3"
            class="mt-2 mb-3"
            v-model="form.comment"
            hide-details
          ></v-textarea>
        </template>
      </table-materials>
    </div>
  </section>
</template>

<script>
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import RequestDetails from "./RequestDetails.vue";
import TableMaterials from "./TableMaterials.vue";
import swal2 from "sweetalert2";
import moment from "moment";
import { STATUS_REQUESTS_MATERIALS } from "@/constants/requestMaterial";
import { authUsuarioComputed } from "@/state/helpers";
import { mapActions, mapGetters } from "vuex";
import { OPTIONS_AMOUNT_FORMATTING } from "@/constants/common";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    approveRequests: {
      type: Array,
      default: () => [],
    },
  },
  components: { TableMaterials, RequestDetails },
  watch: {
    "filters.page": function(page) {
      this.$emit("changePage", page);
    },
  },
  data() {
    return {
      moment,
      optionsFormatting: OPTIONS_AMOUNT_FORMATTING,
      currentRequestMaterials: null,
      materialsToApproveOrReject: [],
      dialogListMaterialsRequest: false,
      statusRequests: STATUS_REQUESTS_MATERIALS,
      filters: {
        page: 1,
        filter: "",
      },
      pageCount: 0,
      tableHeaders: [
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "actions",
        },
        {
          text: "Proyecto",
          align: "center",
          sortable: false,
          value: "project",
        },
        {
          text: "Bodega",
          align: "center",
          sortable: false,
          value: "origin",
        },
        {
          text: "Fecha de creación",
          align: "center",
          sortable: false,
          value: "created_at",
        },
        {
          text: "Estado",
          align: "center",
          sortable: false,
          value: "sent_to_erp",
        },
        {
          text: "Tipo",
          align: "center",
          sortable: false,
          value: "request_type",
        },
        {
          text: "Monto Total",
          align: "center",
          sortable: false,
          value: "amount",
        },
      ],
      breadcrumb: [
        {
          text: "Proyectos",
          disabled: false,
          href: "/",
        },
        {
          text: "Solicitudes de aprobación",
          disabled: true,
          href: "/sap/request-materials",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      approveOrRejectRequestApprove:
        "requestMaterial/approveOrRejectRequestApprove",
      sendCancelRequestApprove: "requestMaterial/cancelRequestApprove",
    }),
    changeCurrentRequestMaterials(requestMaterials) {
      let requestMaterialsUpdated = this.approveRequests.find(
        (item) => item.id == requestMaterials.id
      );
      this.currentRequestMaterials = requestMaterialsUpdated;
    },
    permissionToApprove(requestMaterial) {
      if (this.user && requestMaterial.request_approvers) {
        return (
          requestMaterial.request_approvers.find((item) => {
            return (
              (item.approver.email == this.user.email ||
                (item.approver.substitute &&
                  item.approver.substitute.email == this.user.email)) &&
              !item.approved
            );
          }) !== undefined &&
          !requestMaterial.sent_to_erp &&
          !requestMaterial.rejected &&
          !requestMaterial.cancelled
        );
      }
      return false;
    },
    getColorStatus(item) {
      if (item && (item.rejected || item.cancelled)) {
        return "red darken-2";
      }
      switch (item.sent_to_erp) {
        case this.statusRequests.pending:
          return "yellow darken-4";
        case this.statusRequests.accepted:
          return "blue darken-2";
        default:
          break;
      }
    },
    openModalListMaterialsRequest(requestMaterial, openApprovers = false) {
      let self = this;
      this.currentRequestMaterials = requestMaterial;
      this.dialogListMaterialsRequest = true;
      if (openApprovers) {
        setTimeout(() => {
          self.$refs["requestMaterialItems"].openApproversList();
        }, 1000);
      }
    },
    closeModalListMaterialsRequest($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.currentRequestMaterials = null;
        this.dialogListMaterialsRequest = false;
      }
    },
    async cancelRequestApprove(item) {
      let self = this;
      this.$swal
        .fire({
          icon: "warning",
          title: `¿Estás seguro de anular la solicitud de materiales con código de proyecto: ${item.project}`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: `Anular`,
          confirmButtonColor: "red",
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const response = await self.sendCancelRequestApprove({
              request_id: item.id,
              user: self.user.email,
            });
            if (response.status == 200) {
              self.$swal.fire({
                icon: "success",
                title: "Excelente",
                text: `Solicitud anulada con éxito`,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 4000,
                timerProgressBar: true,
              });
              this.$emit("requestCanceled");
            }
            if (response.status == 500) {
              self.$swal.fire({
                icon: "error",
                title: "Error",
                text:
                  "Ha ocurrido un error en el servidor, intentalo nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 4000,
                timerProgressBar: true,
              });
            }
          }
        });
    },
    async approveOrRejectedRequestMaterial({ item, action }) {
      let self = this;
      self.materialsToApproveOrReject = item.materials;
      let listMaterialsSelectedEl = document.getElementById(
        "listMaterialsSelected"
      );
      await setTimeout(async () => {
        await swal2
          .fire({
            icon: "warning",
            title: `¿Estás seguro de ${
              action ? "aprobar" : "rechazar"
            } la solicitud de materiales con código de proyecto: ${
              item.project
            } con los siguientes materiales?`,
            html: listMaterialsSelectedEl.innerHTML,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: `${action ? "Aprobar" : "Rechazar"}`,
            confirmButtonColor: action ? "primary" : "red",
            cancelButtonText: `Cancelar`,
            input: "textarea",
            inputPlaceholder: action
              ? "Comentario (Opcional)"
              : "Motivo de Rechazo",
            inputValue: "",
            inputAttributes: {
              class: "mt-5",
            },
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              const value = result.value;
              if (!action && !value) {
                self.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "El motivo de rechazo es requerido",
                  position: "top-end",
                  showConfirmButton: false,
                  toast: true,
                  timer: 4000,
                  timerProgressBar: true,
                });
                return;
              }
              const response = await self.approveOrRejectRequestApprove({
                request_id: item.id,
                user: self.user.email,
                approval: action,
                comment: result.value,
              });
              if (response.status == 400) {
                self.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: response.data.detail,
                  position: "top-end",
                  showConfirmButton: false,
                  toast: true,
                  timer: 4000,
                  timerProgressBar: true,
                });
              }
              if (response.status == 200) {
                self.$swal.fire({
                  icon: "success",
                  title: "Excelente",
                  text: `Solicitud ${
                    action ? "Aprobada" : "Rechazada"
                  } con éxito`,
                  position: "top-end",
                  showConfirmButton: false,
                  toast: true,
                  timer: 4000,
                  timerProgressBar: true,
                });
                this.$emit("requestUpdated");
                if (action) {
                  this.$emit("requestApproved");
                }
              }
              if (response.status == 500) {
                self.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text:
                    "Ha ocurrido un error en el servidor, intentalo nuevamente",
                  position: "top-end",
                  showConfirmButton: false,
                  toast: true,
                  timer: 4000,
                  timerProgressBar: true,
                });
              }
            }
          });
      }, 300);
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      loading: "requestMaterial/loading",
    }),
    filteredApproveRequests() {
      if (this.filters.filter) {
        let filter = this.filters.filter.trim().toLowerCase();
        return this.approveRequests.filter((approveRequest) => {
          return (
            approveRequest.project.toLowerCase().includes(filter) ||
            (approveRequest.origin &&
              approveRequest.origin.toLowerCase().includes(filter)) ||
            approveRequest.request_type.toLowerCase().includes(filter)
          );
        });
      }
      return this.approveRequests;
    },
  },
  watch: {
    approveRequests(approveRequests) {
      if (this.currentRequestMaterials) {
        this.changeCurrentRequestMaterials(this.currentRequestMaterials);
      }
    },
  },
};
</script>

<style></style>
