<template>
  <section class="mt-5 pt-3" v-if="currentRequestMaterials">
    <v-row align-content="center" justify="center">
      <v-col cols="12" md="6" sm="12">
        <section v-if="currentRequestMaterials.request_approvers.length">
          <p class="text-center font-weight-bold">
            {{
              currentRequestMaterials.request_approvers.length > 1
                ? "Aprobadores actuales"
                : "Aprobador actual"
            }}
          </p>
          <div
            class="mb-4 mt-5 pt-1"
            v-for="item in currentRequestMaterials.request_approvers"
          >
            <div class="d-flex justify-content-center align-items-center">
              <div class="text-center d-flex flex-column">
                {{ item.approver.name }} {{ item.approver.paternal_last_name }}
                {{ item.approver.maternal_last_name }}
                ({{ item.approver.email }})
                <span class="text-danger mt-2" v-if="item.approver.substitute">
                  <b>Suplente:</b> {{ item.approver.substitute.name }}
                  {{ item.approver.substitute.paternal_last_name }}
                  {{ item.approver.substitute.maternal_last_name }}
                  ({{ item.approver.substitute.email }})
                </span>
                <span class="mt-2 text-success" v-if="item.approved">
                  Aprobado el
                  {{
                    moment(item.approved_at).format("DD/MM/YYYY HH:mm [hrs]")
                  }}
                </span>
              </div>
              <v-chip
                :color="item.approved ? 'green darken-2' : 'yellow darken-4'"
                class="text-white mx-5"
                >{{ item.approved ? "Aprobado" : "Pendiente" }}</v-chip
              >
            </div>
          </div>
        </section>
        <h5 class="text-center mt-5" v-else>
          {{
            currentRequestMaterials.sent_to_erp
              ? "Solicitud aprobada"
              : "Sin aprobadores confirmados"
          }}
        </h5>
      </v-col>
      <v-col
        cols="12"
        md="6"
        sm="12"
        v-if="
          user.email == currentRequestMaterials.created_by &&
            !currentRequestMaterials.sent_to_erp &&
            !currentRequestMaterials.cancelled
        "
      >
        <p class="text-center font-weight-bold">
          {{
            currentRequestMaterials.request_approvers.length
              ? "Modificar"
              : "Confirmar"
          }}
          {{
            Object.values(currentRequestMaterials.possible_approvers).length > 1
              ? "aprobadores"
              : "aprobador"
          }}
        </p>
        <div
          class="mx-auto"
          v-for="(item, index) in currentRequestMaterials.possible_approvers"
        >
          <div class="d-flex align-items-center">
            <v-autocomplete
              :items="getApproversWrapped(item.approvers)"
              :value="item.selected.email"
              item-text="label"
              @change="updateCurrentApprover(item.selected, $event, item)"
              searchable
              class="mt-3 mb-3 py-2 mx-5"
              dense
              hide-details=""
            >
              <template v-slot:item="{ item }">
                <span>
                  {{ item.label }}
                  <span
                    style="font-size: 11px"
                    class="text-danger"
                    v-if="item.substitute"
                  >
                    - SUPLENTE
                  </span>
                </span>
              </template>

              <template v-slot:selection="{ item }">
                <span>
                  {{ item.label }}
                  <span
                    style="font-size: 11px"
                    class="text-danger"
                    v-if="item.substitute"
                  >
                    - SUPLENTE
                  </span>
                </span>
              </template>
            </v-autocomplete>
            <v-btn
              color="primary"
              class="ms-1"
              small
              @click="updateApprovals(item.original, item.selected)"
            >
              {{
                currentRequestMaterials.request_approvers.length
                  ? "Modificar"
                  : "Confirmar"
              }}
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import moment from "moment";

export default {
  props: {
    currentRequestMaterials: {
      default: null,
    },
  },
  data() {
    return {
      moment,
      confirmed: false,
    };
  },
  methods: {
    ...mapActions({
      updateApprovalsRequestApprove:
        "requestMaterial/updateApprovalsRequestApprove",
      updateCurrentApproverRequestApproval:
        "requestMaterial/updateCurrentApproverRequestApproval",
    }),
    getApproversWrapped(approvers) {
      return this.usersActiveContract
        .filter((item) =>
          approvers.some((approver) => approver.email == item.email)
        )
        .map((item) => {
          let approver = approvers.find(
            (approver) => approver.email == item.email
          );
          return {
            value: item.email,
            label: `${item.name} (${item.email})`,
            substitute: approver.substitute,
            original: approver.original,
          };
        });
    },
    updateCurrentApprover(original = null, replacement, item) {
      let replacementComplete = this.getApproversWrapped(item.approvers).find(
        (item) => item.value == replacement
      );
      try {
        this.updateCurrentApproverRequestApproval({
          id: this.currentRequestMaterials.id,
          original,
          replacement: replacementComplete,
        });
      } catch (error) {
        console.log(error);
      }
    },
    async updateApprovals(original = null, approver) {
      this.$swal
        .fire({
          icon: "info",
          title: `¿Deseas ${
            this.currentRequestMaterials.request_approvers.length
              ? "modificar"
              : "confirmar"
          } este aprobador?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "primary",
          confirmButtonText: "Confirmar",
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const response = await this.updateApprovalsRequestApprove({
              user: this.user.email,
              request_id: this.currentRequestMaterials.id,
              original: original
                ? original.substitute
                  ? original.original
                  : original.email
                : approver.substitute
                ? approver.original
                : approver.email,
              replacement: approver.substitute
                ? approver.original
                : approver.email,
            });
            if (response.status == 200) {
              this.$swal.fire({
                icon: "success",
                title: "Excelente",
                text: `Aprobador confirmado con éxito`,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 4000,
                timerProgressBar: true,
              });
              if (
                this.currentRequestMaterials.request_approvers.length !=
                this.currentRequestMaterials.possible_approvers.length
              ) {
                this.confirmed = true;
              }
              this.$emit("approverUpdated");
            }
            if (response.status == 500) {
              this.$swal.fire({
                icon: "error",
                title: "Error",
                text:
                  "Ha ocurrido un error en el servidor, intentalo nuevamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 4000,
                timerProgressBar: true,
              });
            }
          }
        });
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      usersActiveContract: "user/users",
    }),
  },
  watch: {
    currentRequestMaterials: {
      immediate: true,
      handler(requestMaterial) {
        if (requestMaterial) {
          let self = this;
          if (
            self.confirmed &&
            requestMaterial.request_approvers.length ==
              requestMaterial.possible_approvers.length
          ) {
            self.$swal
              .fire({
                icon: "success",
                title: "Excelente",
                text:
                  "La solicitud ha entrado en estado de espera para su aprobación, una vez aprobada se podrá visualizar en el listado de solicitudes",
                confirmButtonText: "Aceptar",
                showConfirmButton: true,
                showCancelButton: false,
              })
              .then(async (result) => {
                self.confirmed = false;
              });
          }
        }
      },
    },
  },
};
</script>

<style></style>
