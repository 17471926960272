var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"p-3"},[_c('loading-flux',{attrs:{"value":_vm.loading}}),_c('section',{staticClass:"d-flex flex-column flex-sm-row justify-content-between"},[_c('h2',{staticClass:"ml-1 my-2"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumb}})],1),_c('v-row',{staticClass:"mt-1",attrs:{"align-content":"center","align-md":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{attrs:{"clearable":"","placeholder":"Buscar"},model:{value:(_vm.filters.filter),callback:function ($$v) {_vm.$set(_vm.filters, "filter", $$v)},expression:"filters.filter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}}),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}},[_c('p',{staticClass:"my-0 text-right mt-3"},[_vm._v(" Número de solicitudes total: "+_vm._s(_vm.filteredApproveRequests.length)+" ")])])],1),_c('v-data-table',{staticClass:"elevation-3 px-5 py-3 mt-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.filteredApproveRequests,"items-per-page":20,"page":_vm.filters.page,"loading":_vm.loading,"loading-text":"Cargando solicitudes","item-key":"id","hide-default-footer":"","no-data-text":"Sin solicitudes"},on:{"update:page":function($event){return _vm.$set(_vm.filters, "page", $event)},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.moment(item.created_at).format("DD/MM/YYYY"))+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s("$ " + item.amount.toLocaleString("es", _vm.optionsFormatting))+" ")])]}},{key:"item.sent_to_erp",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"text-white",attrs:{"color":_vm.getColorStatus(item)}},[_vm._v(" "+_vm._s(item.cancelled ? "Anulada" : item.rejected ? "Rechazada" : item.sent_to_erp ? "Aprobada" : "Pendiente"))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-start"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.openModalListMaterialsRequest(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Detalles")])]),(_vm.permissionToApprove(item))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.approveOrRejectedRequestMaterial({
                  item: item,
                  action: true,
                })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-check ")])],1)]}}],null,true)},[_c('span',[_vm._v("Aprobar solicitud")])]):_vm._e(),(
            (_vm.user &&
              item.created_by === _vm.user.email &&
              !item.cancelled &&
              !item.rejected &&
              !item.sent_to_erp) ||
              _vm.permissionToApprove(item)
          )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red darken-2"},on:{"click":function($event){return _vm.cancelRequestApprove(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Anular Solicitud")])]):_vm._e(),(_vm.permissionToApprove(item))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.approveOrRejectedRequestMaterial({
                  item: item,
                  action: false,
                })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-cancel ")])],1)]}}],null,true)},[_c('span',[_vm._v("Rechazar solicitud")])]):_vm._e()],1)]}}])}),_c('RequestDetails',{ref:"requestMaterialItems",attrs:{"currentRequestMaterials":_vm.currentRequestMaterials,"modalAction":_vm.dialogListMaterialsRequest,"closeModalListMaterialsRequest":_vm.closeModalListMaterialsRequest},on:{"requestUpdated":function($event){return _vm.$emit('requestUpdated')},"approverUpdated":function($event){return _vm.$emit('approverUpdated')}}}),_c('div',{staticStyle:{"display":"none"},attrs:{"id":"listMaterialsSelected"}},[_c('table-materials',{ref:"approveOrRejectRequest",attrs:{"materials":_vm.materialsToApproveOrReject},scopedSlots:_vm._u([{key:"comment",fn:function(){return [_c('v-textarea',{staticClass:"mt-2 mb-3",attrs:{"outlined":"","rows":"3","hide-details":""},model:{value:(_vm.form.comment),callback:function ($$v) {_vm.$set(_vm.form, "comment", $$v)},expression:"form.comment"}})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }