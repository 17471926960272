<template>
  <v-container class="">
    <datatable-approve-requests
      ref="datatable-approve-requests"
      @requestUpdated="getApprovedRequestsData"
      @approverUpdated="getApprovedRequestsData"
      @requestCanceled="getApprovedRequestsData"
      @requestApproved="emitRequestApproved"
      title="Solicitudes de Aprobación"
      :approveRequests="materialsRequestsToApprove"
    ></datatable-approve-requests>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DatatableApproveRequests from "@/components/proyectos/lugarInstalacion/lugarSolicitudMaterial/approveRequests/DatatableApproveRequests.vue";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
export default {
  props: {
    getApprovedRequestsData: {
      type: Function,
      default: () => {},
    },
  },
  components: { DatatableApproveRequests },
  methods: {
    ...authMethods,
    ...mapActions({
      getAllUsersActiveContract: "user/getAllUsersActiveContract",
      cleanRequestsMaterialsToApprove:
        "requestMaterial/cleanRequestsMaterialsToApprove",
    }),
    openModalListMaterialsRequest(requestMaterial) {
      let requestMaterialCreated = this.materialsRequestsToApprove.find(
        (item) => item.id === requestMaterial.id
      );
      this.$refs["datatable-approve-requests"].openModalListMaterialsRequest(
        requestMaterialCreated,
        true
      );
    },
    emitRequestApproved() {
      this.$emit("requestApproved");
    },
  },
  mounted() {
    this.getAllUsersActiveContract({
      date: new Date().toISOString().split("T")[0],
    });
  },
  destroyed() {
    this.cleanRequestsMaterialsToApprove();
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      materialsRequestsToApprove: "requestMaterial/materialsRequestsToApprove",
    }),
  },
};
</script>

<style></style>
