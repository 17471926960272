<template>
  <main class="px-2">
    <loading-flux :value="loadingDispatchGuide"></loading-flux>
    <section
      class="mt-5 d-flex flex-column flex-sm-row justify-content-between"
    >
      <h2 class="ml-1 my-4">
        Devolución de Materiales
        {{
          $route.query.sap_project ? `| COD: ${$route.query.sap_project}` : ""
        }}
      </h2>
      <v-breadcrumbs class="my-0" :items="breadcrumb"></v-breadcrumbs>
    </section>
    <v-row align-content="center" align-md="center" class="my-0">
      <v-col cols="12" md="12" sm="12">
        <v-btn
          color="teal accent-4 text-white"
          @click="openModalAddRequestReturnMaterials"
          :dark="false"
          small
          class="float-left"
        >
          <v-icon small class="mr-1">
            mdi-plus
          </v-icon>
          <span class="text-white">
            Crear solicitud de devolución
          </span>
        </v-btn>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="pb-0 pt-5">
        <label for="items">
          Items por página
        </label>
        <v-autocomplete
          v-model="filters.items"
          :items="[5, 10, 15, 20, 25, 'Todos']"
          @change="($event) => getRequestsReturnMaterialsData(1)"
        >
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        md="4"
        sm="12"
        class="pb-0 pt-0"
        v-if="!$route.query.sap_project"
      >
        <label for="proyect">
          Filtrar por Proyecto
        </label>
        <v-autocomplete
          v-model="filters.project_code"
          clearable
          :items="
            projects.items
              ? projects.items.filter((item) => item.warehouse)
              : []
          "
          @input="
            getStagesFromProject();
            getRequestsReturnMaterialsData(1);
          "
          @update:search-input="getProjectsData"
          @click:clear="getProjectsData"
          class=""
          hide-details
          item-text="name_code"
          no-data-text="Sin proyectos"
          :loading="loadingProjects"
          label="Todos"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="4" sm="12" class="pb-0 pt-0">
        <section>
          <label for="proyect">
            Filtrar por etapa
          </label>
          <v-autocomplete
            label="Etapa del proyecto"
            v-model="filters.sap_stage"
            @change="getRequestsReturnMaterialsData(1)"
            :items="availableStagesProjects"
            :disabled="
              !availableStagesProjects || !availableStagesProjects.length
            "
            clearable
            item-value="sap_code"
            item-text="name"
            hide-details=""
          >
          </v-autocomplete>
        </section>
      </v-col>
    </v-row>
    <v-row align-content="center" align-md="center" class="my-0">
      <v-col cols="12" md="4" sm="12" class="pt-0">
        <v-text-field
          label="Busqueda"
          class="my-0"
          v-model="filters.filter"
          @input="getRequestsReturnMaterialsData(1)"
          clearable
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" sm="12"> </v-col>
    </v-row>
    <v-data-table
      :key="keyTable"
      :headers="tableHeaders"
      :items="
        requestsReturnMaterials.materials
          ? requestsReturnMaterials.materials
          : []
      "
      :page.sync="filters.page"
      :items-per-page="filters.items !== 'Todos' ? filters.items : 100"
      item-key="id"
      :show-select="false"
      :loading="loading"
      class="elevation-3 mt-2"
      loading-text="Cargando solicitudes"
      hide-default-footer
      no-data-text="Sin solicitudes actualmente"
    >
      <template v-slot:item.movement="{ item }">
        <section>
          <v-icon
            small
            color="green"
            v-if="item.destination_code === codeProject"
          >
            mdi-arrow-down
          </v-icon>
          <v-icon small color="red" v-else>
            mdi-arrow-up
          </v-icon>
        </section>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-items-center justify-content-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openModalDetailsRequestReturnMaterials(item)"
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>
            <span>Ver detalles</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="item.showRenewDispatchGuide"
                class=""
                @click="generateDispatchGuide(item, true)"
                icon
                color="green darken-1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>
                  mdi-sync
                </v-icon>
              </v-btn>
            </template>
            <span>
              Solicitar nuevamente Guía de despacho
            </span>
          </v-tooltip>
          <small class="text-danger" v-if="item.countdown">
            {{ getTimeRemainingFormatted(item.countdown) }}
          </small>
        </div>
      </template>
      <template v-slot:item.destination_code="{ item }">
        <div class="d-flex align-items-center justify-content-center">
          <v-icon
            class="mx-1 mt-0"
            :color="item.destination_code ? 'primary' : 'cyan lighten-2'"
          >
            {{ item.destination_code ? "mdi-twitter-retweet" : "mdi-store" }}
          </v-icon>
          <span class="">
            {{
              item.destination_code ? item.destination_code : "Bodega Central"
            }}
          </span>
        </div>
      </template>
      <template v-slot:item.created_at="{ item }">
        <p class="my-0">
          {{ new Date(item.created_at).toLocaleDateString() }}
        </p>
      </template>
      <template v-slot:item.correlative="{ item }">
        <p class="my-0">
          {{
            item.return_erps && item.return_erps.length
              ? item.return_erps[0].return_erp
              : "Sin número"
          }}
        </p>
      </template>
      <template v-slot:item.dispatch_guide="{ item }">
        <p class="my-0">
          {{
            item.return_erps &&
            item.return_erps.length &&
            item.return_erps[0].dispatch_guides.length
              ? item.return_erps[0].dispatch_guides[0].final_number
              : "Sin guía de despacho"
          }}
        </p>
      </template>
    </v-data-table>
    <div class="float-right pt-3">
      <v-pagination
        total-visible="8"
        v-model="filters.page"
        :length="
          requestsReturnMaterials.number_of_pages
            ? requestsReturnMaterials.number_of_pages
            : 1
        "
      ></v-pagination>
    </div>
    <create-request-return-material
      ref="modalRequestReturnMaterial"
      @requestReturnMaterialCreated="getRequestsReturnMaterialsData(1)"
      :modalAction="dialogRequestReturnMaterials"
      :closeModalRequestReturnMaterials="closeModalRequestReturnMaterials"
    ></create-request-return-material>
    <DetailsRequestReturnMaterials
      ref="detailsReturnMaterials"
      :currentRequestReturn="currentRequestReturn"
      :modalAction="dialogDetailsRequestReturnMaterials"
      :closeModalDetailsRequestReturnMaterials="
        closeModalDetailsRequestReturnMaterials
      "
    ></DetailsRequestReturnMaterials>
  </main>
</template>

<script>
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import { mapActions, mapGetters } from "vuex";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import CreateRequestReturnMaterial from "./CreateRequestReturnMaterial.vue";
import DetailsRequestReturnMaterials from "./DetailsRequestReturnMaterials.vue";
import moment from "moment";

export default {
  components: { CreateRequestReturnMaterial, DetailsRequestReturnMaterials },
  data() {
    return {
      breadcrumb: [
        {
          text: "Proyectos",
          disabled: false,
          href: "/",
        },
        {
          text: "Devolución de materiales (SAP)",
          disabled: true,
          href: "/sap/request-materials",
        },
      ],
      requestsReturnMaterials: [],
      loadingDispatchGuide: false,
      loadingProjects: false,
      keyTable: null,
      dialogRequestReturnMaterials: false,
      dialogDetailsRequestReturnMaterials: false,
      currentRequestReturn: null,
      defaultTimeRequestDispatchGuide: 500,
      debounce: null,
      debounceTime: 500,
      debounceProjects: null,
      debounceProjectsTime: 500,
      filters: {
        page: 1,
        items: 10,
        filter: "",
        project_code: null,
        sap_stage: null,
      },
      tableHeaders: [
        {
          text: "Movimiento",
          align: "center",
          sortable: false,
          width: "5%",
          value: "movement",
        },
        {
          text: "Numero de solicitud",
          width: "5%",
          align: "center",
          sortable: false,
          value: "correlative",
        },
        {
          text: "Solicitud de devolución",
          width: "15%",
          sortable: false,
          align: "center",
          value: "destination_code",
        },
        {
          text: "Descripción",
          align: "center",
          sortable: false,
          width: "45%",
          value: "description",
        },
        {
          text: "Fecha de la solicitud",
          align: "center",
          sortable: false,
          value: "created_at",
        },
        {
          text: "Acciones",
          width: "5%",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ],
      codeProject: this.$route.query.sap_project
        ? this.$route.query.sap_project
        : this.$route.params.codigoProyecto,
      codePlace: this.$route.params.codigoLugarInstalacion,
      permissions: {},
    };
  },
  async mounted() {
    let permisos = permiso.obtenerPermisos(
      this.user,
      this.$router.currentRoute.name
    );
    this.permissions = permisos;
    this.getRequestsReturnMaterialsData(1);
  },
  destroyed() {
    this.cleanRequestsReturnMaterials();
  },
  methods: {
    ...authMethods,
    ...mapActions({
      getRequestsReturnMaterialsFromProject:
        "returnMaterial/getRequestsReturnMaterialsFromProject",
      updateCountdownRequestReturnMaterials:
        "returnMaterial/updateCountdownRequestReturnMaterials",
      getDispatchGuideFromReturnMaterialRequest:
        "returnMaterial/getDispatchGuideFromReturnMaterialRequest",
      getProjects: "requestMaterialsSap/getProjects",
      getStagesAvailableFromProject:
        "requestMaterialsSap/getStagesAvailableFromProject",
      clearAvailableStages: "requestMaterialsSap/clearAvailableStages",
    }),
    async getRequestsReturnMaterialsData(page = 1) {
      clearTimeout(this.debounce);
      let self = this;
      this.debounce = setTimeout(async function() {
        self.filters.page = page;
        self.requestsReturnMaterials = await self.getRequestsReturnMaterialsFromProject(
          {
            page: self.filters.page,
            project_code: self.codeProject
              ? self.codeProject
              : self.filters.project_code
              ? self.filters.project_code.split(" - ")[0]
              : undefined,
            filters: self.filters.filter,
            sap_stage: self.filters.sap_stage
              ? self.filters.sap_stage
              : undefined,
            items:
              self.filters.items !== "Todos" ? self.filters.items : undefined,
            show_all: self.filters.items === "Todos" ? true : undefined,
          }
        );
        self.initCountdownDispatchGuidesPending();
      }, self.debounceTime);
    },
    async getProjectsData(search = "") {
      if (
        (search && search.type) ||
        ((search || typeof search == "string") &&
          this.filters.project_code !== search)
      ) {
        this.loadingProjects = true;
        clearTimeout(this.debounceProjects);
        let self = this;
        this.debounceProjects = setTimeout(async function() {
          await self.getProjects({
            filter: search && !search.type ? search : undefined,
            items: 20,
          });
          self.loadingProjects = false;
        }, self.debounceProjectsTime);
      }
    },
    async getStagesFromProject() {
      if (this.filters.project_code || this.$route.query.sap_project) {
        const res = await this.getStagesAvailableFromProject({
          project_code: this.$route.query.sap_project
            ? this.$route.query.sap_project
            : this.filters.project_code?.split(" - ")[0],
        });
        this.filters.sap_stage = null;
      } else {
        this.filters.sap_stage = null;
        this.clearAvailableStages();
      }
    },
    openModalAddRequestReturnMaterials() {
      this.dialogRequestReturnMaterials = true;
      this.filters.project_code = null;
      this.$refs.modalRequestReturnMaterial.resetForm();
      if (!this.$route.query.sap_project) {
        this.getProjectsData({ type: "clear" });
      }
    },
    closeModalRequestReturnMaterials($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.dialogRequestReturnMaterials = false;
      }
    },
    openModalDetailsRequestReturnMaterials(returnRequest) {
      this.currentRequestReturn = returnRequest;
      this.$refs.detailsReturnMaterials.getMaterialsFromRequestReturnData(1);
      this.dialogDetailsRequestReturnMaterials = true;
    },
    closeModalDetailsRequestReturnMaterials() {
      this.currentRequestReturn = null;
      this.dialogDetailsRequestReturnMaterials = false;
    },
    generateDispatchGuide(requestReturn, renewDispatchGuide) {
      let self = this;
      this.$swal
        .fire({
          icon: "warning",
          title: `¿Estás seguro de ${
            renewDispatchGuide ? "volver a solicitar" : "generar"
          } la guía de despacho de esta solicitud?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Generar guía",
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            self.loadingDispatchGuide = true;
            setTimeout(() => {
              self.$swal.fire({
                icon: "success",
                title: "Excelente",
                text: `Guía de despacho solicitada correctamente, espera 5 minutos para descargar el archivo`,
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 5000,
                timerProgressBar: true,
              });
              self.startCountdown(requestReturn);
              self.loadingDispatchGuide = false;
            }, 1000);
          }
        });
    },
    initCountdownDispatchGuidesPending() {
      this.requestsReturnMaterials.materials.forEach((requestReturn) => {
        if (
          requestReturn.dispatchGuide &&
          this.getTimeDifferenceInSecondsFromTheDispatchGuideRequestAndTheCurrentTime(
            requestReturn
          ) < this.defaultTimeRequestDispatchGuide
        ) {
          this.startCountdown(
            requestReturn,
            this.getTimeDifferenceInSecondsFromTheDispatchGuideRequestAndTheCurrentTime(
              requestReturn
            )
          );
        }
      });
    },
    async startCountdown(requestReturn, defaultDurationInSeconds = null) {
      let self = this;
      let timeToDownloadDispatchGuide = defaultDurationInSeconds
        ? defaultDurationInSeconds
        : this.defaultTimeRequestDispatchGuide;
      await this.updateCountdownRequestReturnMaterials({
        countdown: timeToDownloadDispatchGuide,
        requestReturn: requestReturn,
      });
      let countdownInterval = setInterval(async () => {
        timeToDownloadDispatchGuide--;
        self.keyTable++;
        self.updateCountdownRequestReturnMaterials({
          countdown: timeToDownloadDispatchGuide,
          requestReturn: requestReturn,
        });
        if (timeToDownloadDispatchGuide <= 0) {
          clearInterval(countdownInterval);
          const resp = await self.getDispatchGuideFromReturnMaterialRequest({});
          if (!resp) {
            self.$swal.fire({
              icon: "warning",
              title: "Atención",
              text: `La guía de despacho aun no se encuentra disponible, espera un poco mas para descargar el archivo`,
              position: "top-end",
              showConfirmButton: false,
              toast: true,
              timer: 5000,
              timerProgressBar: true,
            });
            return self.startCountdown(requestReturn);
          } else {
            self.updateCountdownRequestReturnMaterials({
              countdown: null,
              requestReturn: requestReturn,
            });
            self.$swal.fire({
              icon: "success",
              title: "Excelente",
              text: `Guía de despacho descagada correctamente`,
              position: "top-end",
              showConfirmButton: false,
              toast: true,
              timer: 5000,
              timerProgressBar: true,
            });
          }
        }
      }, 1000);
    },
    updateCountdownRequestReturnMaterials(payload) {
      if (!payload.requestReturn.countdown) {
        payload.requestReturn.showRenewDispatchGuide = false;
      }
      payload.requestReturn.countdown = payload.countdown;
      if (!payload.requestReturn.countdown) {
        payload.requestReturn.showRenewDispatchGuide = true;
      }
    },
    getTimeDifferenceInSecondsFromTheDispatchGuideRequestAndTheCurrentTime(
      requestReturn
    ) {
      return moment().diff(requestReturn.dispatchGuide.created_at, "seconds");
    },
    getTimeRemainingFormatted(time) {
      let seconds = time % 60;
      return `${Math.floor(time / 60)}:${seconds.toString().padStart(2, "0")}`;
    },
    cleanRequestsReturnMaterials() {
      this.requestsReturnMaterials = [];
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      loading: "returnMaterial/loadingRequests",
      projects: "requestMaterialsSap/projects",
      availableStagesProjects: "requestMaterialsSap/availableStagesProjects",
    }),
  },
  watch: {
    "filters.page": function(page) {
      this.getRequestsReturnMaterialsData(page);
    },
    projects(newProjects) {
      if (newProjects.items && this.$route.query.sap_project) {
        this.getStagesFromProject();
      }
    },
    "filters.project_code": function(project) {
      console.log(project);
      if (!project) {
        this.clearAvailableStages();
      }
    },
  },
};
</script>

<style></style>
