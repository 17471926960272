var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"px-2"},[_c('loading-flux',{attrs:{"value":_vm.loadingDispatchGuide}}),_c('section',{staticClass:"mt-5 d-flex flex-column flex-sm-row justify-content-between"},[_c('h2',{staticClass:"ml-1 my-4"},[_vm._v(" Devolución de Materiales "+_vm._s(_vm.$route.query.sap_project ? ("| COD: " + (_vm.$route.query.sap_project)) : "")+" ")]),_c('v-breadcrumbs',{staticClass:"my-0",attrs:{"items":_vm.breadcrumb}})],1),_c('v-row',{staticClass:"my-0",attrs:{"align-content":"center","align-md":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-btn',{staticClass:"float-left",attrs:{"color":"teal accent-4 text-white","dark":false,"small":""},on:{"click":_vm.openModalAddRequestReturnMaterials}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_c('span',{staticClass:"text-white"},[_vm._v(" Crear solicitud de devolución ")])],1)],1),_c('v-col',{staticClass:"pb-0 pt-5",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('label',{attrs:{"for":"items"}},[_vm._v(" Items por página ")]),_c('v-autocomplete',{attrs:{"items":[5, 10, 15, 20, 25, 'Todos']},on:{"change":function ($event) { return _vm.getRequestsReturnMaterialsData(1); }},model:{value:(_vm.filters.items),callback:function ($$v) {_vm.$set(_vm.filters, "items", $$v)},expression:"filters.items"}})],1),(!_vm.$route.query.sap_project)?_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('label',{attrs:{"for":"proyect"}},[_vm._v(" Filtrar por Proyecto ")]),_c('v-autocomplete',{attrs:{"clearable":"","items":_vm.projects.items
            ? _vm.projects.items.filter(function (item) { return item.warehouse; })
            : [],"hide-details":"","item-text":"name_code","no-data-text":"Sin proyectos","loading":_vm.loadingProjects,"label":"Todos"},on:{"input":function($event){_vm.getStagesFromProject();
          _vm.getRequestsReturnMaterialsData(1);},"update:search-input":_vm.getProjectsData,"click:clear":_vm.getProjectsData},model:{value:(_vm.filters.project_code),callback:function ($$v) {_vm.$set(_vm.filters, "project_code", $$v)},expression:"filters.project_code"}})],1):_vm._e(),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('section',[_c('label',{attrs:{"for":"proyect"}},[_vm._v(" Filtrar por etapa ")]),_c('v-autocomplete',{attrs:{"label":"Etapa del proyecto","items":_vm.availableStagesProjects,"disabled":!_vm.availableStagesProjects || !_vm.availableStagesProjects.length,"clearable":"","item-value":"sap_code","item-text":"name","hide-details":""},on:{"change":function($event){return _vm.getRequestsReturnMaterialsData(1)}},model:{value:(_vm.filters.sap_stage),callback:function ($$v) {_vm.$set(_vm.filters, "sap_stage", $$v)},expression:"filters.sap_stage"}})],1)])],1),_c('v-row',{staticClass:"my-0",attrs:{"align-content":"center","align-md":"center"}},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{staticClass:"my-0",attrs:{"label":"Busqueda","clearable":"","append-icon":"mdi-magnify"},on:{"input":function($event){return _vm.getRequestsReturnMaterialsData(1)}},model:{value:(_vm.filters.filter),callback:function ($$v) {_vm.$set(_vm.filters, "filter", $$v)},expression:"filters.filter"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}})],1),_c('v-data-table',{key:_vm.keyTable,staticClass:"elevation-3 mt-2",attrs:{"headers":_vm.tableHeaders,"items":_vm.requestsReturnMaterials.materials
        ? _vm.requestsReturnMaterials.materials
        : [],"page":_vm.filters.page,"items-per-page":_vm.filters.items !== 'Todos' ? _vm.filters.items : 100,"item-key":"id","show-select":false,"loading":_vm.loading,"loading-text":"Cargando solicitudes","hide-default-footer":"","no-data-text":"Sin solicitudes actualmente"},on:{"update:page":function($event){return _vm.$set(_vm.filters, "page", $event)}},scopedSlots:_vm._u([{key:"item.movement",fn:function(ref){
        var item = ref.item;
return [_c('section',[(item.destination_code === _vm.codeProject)?_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v(" mdi-arrow-down ")]):_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-arrow-up ")])],1)]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.openModalDetailsRequestReturnMaterials(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver detalles")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(item.showRenewDispatchGuide)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"green darken-1"},on:{"click":function($event){return _vm.generateDispatchGuide(item, true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-sync ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" Solicitar nuevamente Guía de despacho ")])]),(item.countdown)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.getTimeRemainingFormatted(item.countdown))+" ")]):_vm._e()],1)]}},{key:"item.destination_code",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('v-icon',{staticClass:"mx-1 mt-0",attrs:{"color":item.destination_code ? 'primary' : 'cyan lighten-2'}},[_vm._v(" "+_vm._s(item.destination_code ? "mdi-twitter-retweet" : "mdi-store")+" ")]),_c('span',{},[_vm._v(" "+_vm._s(item.destination_code ? item.destination_code : "Bodega Central")+" ")])],1)]}},{key:"item.created_at",fn:function(ref){
        var item = ref.item;
return [_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(new Date(item.created_at).toLocaleDateString())+" ")])]}},{key:"item.correlative",fn:function(ref){
        var item = ref.item;
return [_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.return_erps && item.return_erps.length ? item.return_erps[0].return_erp : "Sin número")+" ")])]}},{key:"item.dispatch_guide",fn:function(ref){
        var item = ref.item;
return [_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.return_erps && item.return_erps.length && item.return_erps[0].dispatch_guides.length ? item.return_erps[0].dispatch_guides[0].final_number : "Sin guía de despacho")+" ")])]}}])}),_c('div',{staticClass:"float-right pt-3"},[_c('v-pagination',{attrs:{"total-visible":"8","length":_vm.requestsReturnMaterials.number_of_pages
          ? _vm.requestsReturnMaterials.number_of_pages
          : 1},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}})],1),_c('create-request-return-material',{ref:"modalRequestReturnMaterial",attrs:{"modalAction":_vm.dialogRequestReturnMaterials,"closeModalRequestReturnMaterials":_vm.closeModalRequestReturnMaterials},on:{"requestReturnMaterialCreated":function($event){return _vm.getRequestsReturnMaterialsData(1)}}}),_c('DetailsRequestReturnMaterials',{ref:"detailsReturnMaterials",attrs:{"currentRequestReturn":_vm.currentRequestReturn,"modalAction":_vm.dialogDetailsRequestReturnMaterials,"closeModalDetailsRequestReturnMaterials":_vm.closeModalDetailsRequestReturnMaterials}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }