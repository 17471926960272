var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentRequestMaterials)?_c('section',{staticClass:"mt-5 pt-3"},[_c('v-row',{attrs:{"align-content":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[(_vm.currentRequestMaterials.request_approvers.length)?_c('section',[_c('p',{staticClass:"text-center font-weight-bold"},[_vm._v(" "+_vm._s(_vm.currentRequestMaterials.request_approvers.length > 1 ? "Aprobadores actuales" : "Aprobador actual")+" ")]),_vm._l((_vm.currentRequestMaterials.request_approvers),function(item){return _c('div',{staticClass:"mb-4 mt-5 pt-1"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"text-center d-flex flex-column"},[_vm._v(" "+_vm._s(item.approver.name)+" "+_vm._s(item.approver.paternal_last_name)+" "+_vm._s(item.approver.maternal_last_name)+" ("+_vm._s(item.approver.email)+") "),(item.approver.substitute)?_c('span',{staticClass:"text-danger mt-2"},[_c('b',[_vm._v("Suplente:")]),_vm._v(" "+_vm._s(item.approver.substitute.name)+" "+_vm._s(item.approver.substitute.paternal_last_name)+" "+_vm._s(item.approver.substitute.maternal_last_name)+" ("+_vm._s(item.approver.substitute.email)+") ")]):_vm._e(),(item.approved)?_c('span',{staticClass:"mt-2 text-success"},[_vm._v(" Aprobado el "+_vm._s(_vm.moment(item.approved_at).format("DD/MM/YYYY HH:mm [hrs]"))+" ")]):_vm._e()]),_c('v-chip',{staticClass:"text-white mx-5",attrs:{"color":item.approved ? 'green darken-2' : 'yellow darken-4'}},[_vm._v(_vm._s(item.approved ? "Aprobado" : "Pendiente"))])],1)])})],2):_c('h5',{staticClass:"text-center mt-5"},[_vm._v(" "+_vm._s(_vm.currentRequestMaterials.sent_to_erp ? "Solicitud aprobada" : "Sin aprobadores confirmados")+" ")])]),(
        _vm.user.email == _vm.currentRequestMaterials.created_by &&
          !_vm.currentRequestMaterials.sent_to_erp &&
          !_vm.currentRequestMaterials.cancelled
      )?_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('p',{staticClass:"text-center font-weight-bold"},[_vm._v(" "+_vm._s(_vm.currentRequestMaterials.request_approvers.length ? "Modificar" : "Confirmar")+" "+_vm._s(Object.values(_vm.currentRequestMaterials.possible_approvers).length > 1 ? "aprobadores" : "aprobador")+" ")]),_vm._l((_vm.currentRequestMaterials.possible_approvers),function(item,index){return _c('div',{staticClass:"mx-auto"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-autocomplete',{staticClass:"mt-3 mb-3 py-2 mx-5",attrs:{"items":_vm.getApproversWrapped(item.approvers),"value":item.selected.email,"item-text":"label","searchable":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.updateCurrentApprover(item.selected, $event, item)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.label)+" "),(item.substitute)?_c('span',{staticClass:"text-danger",staticStyle:{"font-size":"11px"}},[_vm._v(" - SUPLENTE ")]):_vm._e()])]}},{key:"selection",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.label)+" "),(item.substitute)?_c('span',{staticClass:"text-danger",staticStyle:{"font-size":"11px"}},[_vm._v(" - SUPLENTE ")]):_vm._e()])]}}],null,true)}),_c('v-btn',{staticClass:"ms-1",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.updateApprovals(item.original, item.selected)}}},[_vm._v(" "+_vm._s(_vm.currentRequestMaterials.request_approvers.length ? "Modificar" : "Confirmar")+" ")])],1)])})],2):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }