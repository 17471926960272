var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('loading-flux',{attrs:{"value":_vm.loading}}),_c('v-dialog',{attrs:{"max-width":"1200px"},on:{"keydown":_vm.executeCloseModalRequestReturnMaterials,"click:outside":_vm.executeCloseModalRequestReturnMaterials},model:{value:(_vm.modalAction),callback:function ($$v) {_vm.modalAction=$$v},expression:"modalAction"}},[_c('v-card',[_c('div',{staticClass:"p-4 pb-0"},[_c('h5',[_vm._v(" Crear solicitud de devolución de materiales ")])]),_c('hr'),_c('v-spacer'),_c('v-card-text',{staticClass:"pb-0"},[_c('v-container',[_c('v-row',{attrs:{"align":"start","align-content":"start"}},[_c('v-col',{staticClass:"mb-1 pt-0 ml-1",attrs:{"cols":"12","md":"4","sm":"12"}},[(!_vm.$route.query.sap_project)?_c('v-autocomplete',{attrs:{"items":_vm.projects.items
                    ? _vm.projects.items.filter(function (item) { return item.warehouse; })
                    : [],"item-text":"name_code","label":"Proyecto","no-data-text":"Sin proyectos","loading":_vm.loadingProjects},on:{"input":function($event){return _vm.getStagesFromProject()},"update:search-input":_vm.getProjectsData,"click:clear":_vm.getProjectsData},model:{value:(_vm.form.project),callback:function ($$v) {_vm.$set(_vm.form, "project", $$v)},expression:"form.project"}}):_vm._e(),(!_vm.$route.query.sap_project && !_vm.form.project)?_c('small',{staticClass:"text-danger"},[_vm._v(" Selecciona un proyecto y su etapa para buscar los materiales disponibles para devolución ")]):_vm._e(),(_vm.validationShowInfoProjectsAndStages)?_c('section',[_c('label',{staticClass:"font-weight-bold"},[_vm._v(" Unidad de Negocio ")]),_c('p',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.getCurrentProjectFromCode.business_unit.name)+" ")]),_c('label',{staticClass:"font-weight-bold"},[_vm._v(" Tipo de Venta ")]),_c('p',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.getCurrentProjectFromCode.sale_type)+" ")])]):_vm._e()],1),_c('v-col',{staticClass:"mb-1 pt-0 ml-1",attrs:{"cols":"12","md":"4","sm":"12"}},[_c('section',[_c('v-autocomplete',{attrs:{"label":"Etapa del proyecto","items":_vm.availableStagesProjects,"disabled":!_vm.availableStagesProjects ||
                      !_vm.availableStagesProjects.length ||
                      _vm.statusAvailableProjects === 404 ||
                      !_vm.validationShowInfoProjectsAndStages,"item-value":"sap_code","item-text":"name"},on:{"change":_vm.getMaterialsFromErp},model:{value:(_vm.form.sap_stage),callback:function ($$v) {_vm.$set(_vm.form, "sap_stage", $$v)},expression:"form.sap_stage"}}),(
                    (_vm.form.project_code || _vm.codeProject) &&
                      (!_vm.availableStagesProjects ||
                        !_vm.availableStagesProjects.length ||
                        _vm.statusAvailableProjects === 404)
                  )?_c('p',{staticClass:"text-danger"},[_c('b',[_vm._v("Este proyecto no tiene etapas de proyecto activas")])]):_vm._e()],1)])],1),_c('v-data-table',{staticClass:"px-1 elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.materialsAvailable.materials ? _vm.materialsAvailable.materials : [],"items-per-page":_vm.filters.items !== 'Todos' ? _vm.filters.items : 100,"page":_vm.filters.page,"hide-default-footer":"","loading":_vm.loadingMaterialsAvailable,"loading-text":"Cargando materiales","show-select":"","item-key":"id","no-data-text":"Sin materiales"},on:{"update:page":function($event){return _vm.$set(_vm.filters, "page", $event)},"page-count":function($event){_vm.pageCount = $event},"toggle-select-all":_vm.updateQuantityRowSelected,"item-selected":_vm.updateQuantityRowSelected},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pt-5",attrs:{"flat":""}},[_c('v-text-field',{staticClass:"w-25 mx-4",attrs:{"label":"Busqueda","disabled":!_vm.form.sap_stage || (!_vm.codeProject && !_vm.form.project_code),"clearable":"","placeholder":"Buscar material","append-icon":"mdi-magnify"},on:{"input":function($event){return _vm.getMaterials(1)}},model:{value:(_vm.filters.filter),callback:function ($$v) {_vm.$set(_vm.filters, "filter", $$v)},expression:"filters.filter"}}),_c('v-autocomplete',{staticClass:"w-25",attrs:{"items":[5, 10, 15, 20, 25, 'Todos'],"label":"Items por página"},on:{"change":function ($event) { return _vm.getMaterials(1); }},model:{value:(_vm.filters.items),callback:function ($$v) {_vm.$set(_vm.filters, "items", $$v)},expression:"filters.items"}}),_c('v-divider',{staticClass:"mx-5",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('div',{staticClass:"d-flex align-items-center"},[_c('p',{staticClass:"mx-3 text-h6"},[_c('small',{staticClass:"pull-right"},[_vm._v(" Seleccionados: "+_vm._s(_vm.materialsSelected.length)+" ")])])])],1)]},proxy:true},{key:"item.code",fn:function(ref){
                  var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(item.material.code))])]}},{key:"item.available",fn:function(ref){
                  var item = ref.item;
return [_c('p',{staticClass:"my-0 text-right",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(item.available_quantity)+" ")])]}},{key:"item.description",fn:function(ref){
                  var item = ref.item;
return [_c('p',{staticClass:"my-0 text-left",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(item.material.description)+" ")])]}},{key:"item.units",fn:function(ref){
                  var item = ref.item;
return [_c('p',{staticClass:"my-0 text-center",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(item.material.measurement.units)+" ")])]}},{key:"item.request",fn:function(ref){
                  var item = ref.item;
return [(_vm.materialIsSelected(item))?_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"type":"number","min":1,"max":item.available_quantity,"error":_vm.materialIsSelected(item)
                    ? _vm.materialIsSelected(item).request >
                      item.available_quantity
                    : false,"value":_vm.materialIsSelected(item) &&
                  _vm.materialIsSelected(item).request !== undefined
                    ? _vm.materialIsSelected(item).request
                    : item.available_quantity,"hide-details":""},on:{"input":function($event){return _vm.setMaterialRequestValue($event, item)}}}):_vm._e()]}}]),model:{value:(_vm.materialsSelected),callback:function ($$v) {_vm.materialsSelected=$$v},expression:"materialsSelected"}}),_c('div',{staticClass:"d-flex justify-content-end pt-4"},[_c('v-pagination',{attrs:{"length":_vm.materialsAvailable.number_of_pages
                  ? _vm.materialsAvailable.number_of_pages
                  : 1,"total-visible":8},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.loading,"color":"red darken-1","outlined":"","text":""},on:{"click":_vm.executeCloseModalRequestReturnMaterials}},[_c('small',[_vm._v(" Cancelar ")])]),_c('v-btn',{staticClass:"text-white",attrs:{"color":"teal accent-4","loading":_vm.loading,"disabled":_vm.materialsSelected.length < 1},on:{"click":_vm.validateMaterialsSelectedAndOpenConfirm}},[_c('small',{staticClass:"text-white"},[_vm._v(" Crear solicitud ")])])],1)],1)],1),_c('ConfirmRequestReturnMaterial',{ref:"confirmMaterials",attrs:{"modalAction":_vm.dialogConfirmRequestReturnMaterial,"closeModalConfirmRequestReturnMaterial":_vm.closeModalConfirmRequestReturnMaterial},on:{"requestReturnMaterialCreated":_vm.closeModalCreateRequestAndEmitLoadReturnRequests}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }