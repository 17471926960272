<template>
  <v-card>
    <b-overlay :show="loading" variant="white" rounded="sm">
      <div class="card">
        <div class="card-body">
          <div class="d-flex" v-if="showTitle">
            <div class="card-title col-auto me-auto titulo-tarjetas">
              Gestión de Materiales
            </div>
          </div>
          <v-tabs v-model="tab" fixed-tabs>
            <v-tab key="requests">
              Solicitudes aprobadas
            </v-tab>
            <v-tab id="requestsPending" key="requests-pending">
              Solicitudes de Aprobación
            </v-tab>
            <v-tab key="consumption">
              Consumo de materiales
            </v-tab>
            <v-tab key="returns">
              Devolución de materiales
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item key="requests">
              <b-alert
                :show="segundosMensajeActualizacion"
                dismissible
                :variant="mensaje.variant"
                @dismissed="segundosMensajeActualizacion = 0"
                @dismiss-count-down="actualizarSegundosMensajeActualizacion"
              >
                {{ mensaje.texto }}
              </b-alert>
              <div
                class="py-5"
                v-if="proyectoUsuario && proyectoUsuario.panel_especial"
              >
                <v-alert color="orange lighten-2" type="info">
                  <span class="brown--text text--darken-4"
                    >Recuerde que este proyecto tiene el tablero personalizado:
                    <b>{{ proyectoUsuario.panel_especial }}</b></span
                  >
                </v-alert>
              </div>
              <div class="mt-5 pt-5" v-if="rolModuloPermiso.escritura">
                <div class="botones-menu">
                  <button
                    class="btn btn-success col-12"
                    @click="abrirModalNuevo('Solicitud', 'completa')"
                  >
                    <i class="mdi mdi-plus me-1"></i>
                    Crear Solicitud
                  </button>
                </div>
                <div class="botones-menu">
                  <button
                    class="btn btn-success col-12"
                    @click="abrirModalNuevo('Solicitud', 'rapida')"
                  >
                    <i class="mdi mdi-plus me-1"></i>
                    Vale Consumo
                  </button>
                </div>

                <div class="botones-menu float-end">
                  <button
                    class="btn btn-primary col-12"
                    @click="exportarExcel()"
                    v-if="solicitudMaterialesFiltro.length > 0"
                  >
                    <i class="bx bx-download me-1"></i>
                    Exportar
                  </button>
                </div>
              </div>

              <div v-if="solicitudMaterialesFiltro.length > 0" class="mt-1">
                <div class="row mt-3">
                  <div class="col-lg-3">
                    <label>Filtrar por bodega:</label>
                    <div class="mb-1">
                      <multiselect
                        v-model="bodegaMovil"
                        :options="bodegasFiltro"
                        placeholder="Seleccionar Bodega"
                        value="codigo_bodega"
                        label="codigo_bodega"
                        :searchable="true"
                        :loading="bodegasFiltroCargando"
                        open-direction="bottom"
                        class="helo"
                        @select="filtrarSolicitudes"
                        @remove="eliminarFiltrarSolicitudes"
                      ></multiselect>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_length"
                      class="dataTables_length"
                    ></div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-end"
                    >
                      <label class="d-inline-flex align-items-center">
                        Buscar:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          placeholder="Buscar..."
                          class="form-control form-control-sm ms-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                </div>
                <b-table
                  v-if="renderComponent"
                  class="datatables tabla-personalizada"
                  :items="solicitudMaterialesFiltro"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  select-mode="single"
                  selectable
                  @filtered="onFiltered"
                  :busy="solicitudMaterialesCargando"
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                  </template>
                  <template #cell(accion)="data">
                    <i
                      class="bx bx-detail text-primary icono-botom-tabla"
                      @click="abrirModalDetalle(data.item)"
                      v-b-tooltip.focus
                      title="Ver productos de la solicitud"
                    ></i>
                    <!-- <i
                      class="bx bx-list-ul text-primary icono-botom-tabla"
                      @click="abrirModalDetalleERP(data.item)"
                      v-b-tooltip.focus
                      title="Ver comentarios de la solicitud y de los productos"
                    ></i> -->
                    <i
                      v-if="data.item.tipo_bodega == 'Central'"
                      class="mdi mdi-truck-fast-outline text-primary icono-botom-tabla"
                      @click="abrirModalSolicitudDespacho(data.item)"
                      v-b-tooltip.focus
                      title="Ver las solicitudes de despacho"
                    ></i>
                  </template>
                  <template #cell(nombre_usuario)="data">
                    <span class="font-size-13">
                      {{ data.item.nombre_usuario }} ({{ data.item.email }})
                    </span>
                  </template>
                  <template #cell(tipo_solicitud)="data">
                    <span
                      class="badge font-size-13"
                      :class="colorTipoSolicitud(data.item.tipo_solicitud)"
                    >
                      {{ data.item.tipo_solicitud }}
                    </span>
                  </template>
                  <template #cell(nombre_estado_solicitud)="data">
                    <span
                      class="badge font-size-13"
                      :class="
                        colorTipoSolicitud(data.item.nombre_estado_solicitud)
                      "
                    >
                      {{ data.item.nombre_estado_solicitud }}
                    </span>
                  </template>
                  <template #cell(tipo_bodega)="data">
                    <span
                      class="badge font-size-13"
                      :class="colorTipoSolicitud(data.item.tipo_bodega)"
                    >
                      {{ data.item.tipo_bodega }}
                    </span>
                  </template>
                  <template #cell(fecha_creacion)="data">
                    {{ formatDateTime(data.item.fecha_creacion) }}
                  </template>
                  <template #cell(fecha_entrega)="data">
                    {{ formatDate(data.item.fecha_entrega) }}
                  </template>
                </b-table>

                <div class="row mt-4">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalRows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="solicitudMaterialesCargando" class="py-5">
                <h5 class="text-center">Cargando Gestiones</h5>
              </div>
              <div v-else class="py-5">
                <v-alert color="red lighten-3" type="info">
                  <span class="brown--text text--darken-4"
                    >Aún no hay gestiones para mostrar</span
                  >
                </v-alert>
              </div>
            </v-tab-item>
            <v-tab-item key="requests-pending">
              <index-approve-requests
                ref="approve-requests"
                :getApprovedRequestsData="getApprovedRequestsData"
              >
              </index-approve-requests>
            </v-tab-item>
            <v-tab-item key="consumption">
              <consumption-materials></consumption-materials>
            </v-tab-item>
            <v-tab-item key="returns">
              <return-materials></return-materials>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </b-overlay>

    <b-modal
      v-model="mostrarModal"
      title="Agregar Solicitud"
      hide-footer
      size="xl"
      no-close-on-backdrop
    >
      <loading-flux :value="loadingSolicitud"></loading-flux>
      <b-alert
        :show="segundosMensajeCrearSolicitudModal"
        dismissible
        :variant="mensaje.variant"
        @dismissed="segundosMensajeCrearSolicitudModal = 0"
        @dismiss-count-down="actualizarSegundosMensajeCrearSolicitudModal"
      >
        {{ mensaje.texto }}
      </b-alert>
      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-lg-6">
            <div class="mb-3">
              <label for="tipoBodega">Tipo Bodega</label>
              <input
                v-model="tipo_bodega_from_button"
                type="text"
                class="form-control"
                readonly
              />
              <div
                v-if="submitted && !$v.form.tipoBodega.required"
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-lg-6" v-show="mostrarBodegaMovil">
            <div class="mb-3">
              <label for="bodega">Bodega Movil</label>
              <multiselect
                v-model="$v.form.bodega.$model"
                :options="bodegas"
                placeholder="Seleccionar Bodega"
                value="codigo_bodega"
                label="codigo_bodega"
                :searchable="true"
                :loading="bodegasCargando"
                open-direction="bottom"
                class="helo"
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.form.bodega.$model == null &&
                    mostrarBodegaMovil,
                }"
                @select="habilitarProductos"
                :disabled="bloquearControles == 1"
              ></multiselect>
              <div
                v-if="
                  submitted &&
                    $v.form.bodega.$model == null &&
                    mostrarBodegaMovil
                "
                class="invalid-feedback"
              >
                El campo es obligatorio.
              </div>
            </div>
          </div>
          <div class="col-lg-6" v-if="maneraCrearSolicitud != 'rapida'">
            <div class="mb-3">
              <label for="bodega"
                >Seleccionar Tipo de Solicitud de Materiales</label
              >
              <multiselect
                v-model="form.tipoSolicitudMaterial"
                :options="typeRequests"
                placeholder="Seleccionar tipo de solicitud"
                value="value"
                label="name"
                :searchable="true"
                open-direction="bottom"
                class="helo"
              ></multiselect>
            </div>
            <p class="text-info">
              <b>IMPORTANTE</b>: En este selector puedes cambiar el tipo de
              solicitud de materiales que deseas crear
            </p>
          </div>
        </div>
        <div class="text-end mb-3">
          <b-button
            v-if="rolModuloPermiso.escritura"
            type="submit"
            variant="success"
            class="ms-1"
            @click="abrirModalProductos"
            >Agregar Materiales</b-button
          >
        </div>

        <div class="row">
          <div class="table-responsive table-solicitud-material-detalle">
            <table class="table mb-0 tabla-personalizada" v-if="recargarTabla">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Acción</th>
                  <th>Código</th>
                  <th>Producto</th>
                  <th>Cantidad</th>
                  <th>Comentario</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(producto, i) in productosSeleccionados" :key="i">
                  <td>
                    {{ (i += 1) }}
                  </td>
                  <td v-if="rolModuloPermiso.escritura">
                    <i
                      class="bx bx-trash text-primary icono-botom-tabla"
                      @click="eliminarProducto(producto)"
                    ></i>
                  </td>
                  <td>{{ producto.Producto }}</td>
                  <td>{{ producto.Descripción }}</td>
                  <td>
                    <input
                      type="number"
                      class="form-control"
                      v-model="producto.cantidad"
                      @keypress="validarNumero"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      class="form-control"
                      v-model="producto.comentario"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="mb-3 mt-3">
            <label for="solicitudComentario">Comentario Solicitud</label>
            <textarea
              id="solicitudComentario"
              v-model="$v.form.solicitudComentario.$model"
              type="text"
              class="form-control"
              placeholder="Comentario"
              :disabled="bloquearControles == 1"
              :class="{
                'is-invalid': submitted && $v.form.solicitudComentario.$error,
              }"
              maxlength="500"
              rows="4"
            />
            <div
              v-if="submitted && !$v.form.solicitudComentario.required"
              class="invalid-feedback"
            >
              El campo es obligatorio.
            </div>
          </div>
        </div>
        <div class="text-end mt-3">
          <b-button variant="light" @click="mostrarModal = false"
            >Cerrar</b-button
          >
          <b-button
            v-if="rolModuloPermiso.escritura"
            type="button"
            variant="success"
            class="ms-1"
            @click="crearSolicitud()"
            >Guardar</b-button
          >
        </div>
      </form>
    </b-modal>

    <b-modal
      v-model="mostrarModalProductos"
      title="Buscar Materiales"
      hide-footer
      size="xl"
      no-close-on-backdrop
    >
      <SolicitudMaterialBuscarProductos
        :solicitudMaterial="solicitudMaterial"
        @agregarProductosSeleccionados="agregarProductosSeleccionados"
      />
    </b-modal>

    <b-modal
      v-model="mostrarModalDetalle"
      title="Detalle Solicitud"
      hide-footer
      hide-header
      size="xl"
    >
      <SolicitudMaterialDetalle
        :solicitudMaterial="solicitudSeleccionada"
        :lugarInstalacion="lugarInstalacion"
        @cerrarDetalle="cerrarDetalle"
      />
    </b-modal>

    <b-modal
      v-model="mostrarModalDetalleERP"
      :title="
        `Detalle Solicitud ERP - ${
          solicitudSeleccionada.solicitud_correlativo != null
            ? `COD ${solicitudSeleccionada.solicitud_correlativo}`
            : ''
        }`
      "
      hide-footer
      size="xl"
    >
      <SolicitudMaterialDetalleERP
        :solicitudMaterial="solicitudSeleccionada"
        :lugarInstalacion="lugarInstalacion"
        @cerrarDetalle="cerrarDetalleERP"
      />
    </b-modal>

    <b-modal
      v-model="mostrarModalSolicitudDespacho"
      :title="
        `Solicitud Despacho - ${
          solicitudSeleccionada.solicitud_correlativo != null
            ? `COD ${solicitudSeleccionada.solicitud_correlativo}`
            : ''
        } | Tipo de solicitud: ${
          solicitudSeleccionada.tipo_documento == REQUEST_MATERIAL
            ? 'Normal'
            : 'Urgente'
        }`
      "
      hide-footer
      size="xl"
    >
      <SolicitudMaterialDespacho
        :solicitudMaterial="solicitudSeleccionada"
        :lugarInstalacion="lugarInstalacion"
        @cerrarSolicitudDespacho="cerrarSolicitudDespacho"
      />
    </b-modal>

    <b-modal
      v-model="mostrarModalProductoSolicitadoNuevamente"
      title="Detalle Solicitud"
      hide-footer
      hide-header
      size="lg"
    >
      <div class="row contenedor-producto-anterior">
        <div
          class="mb-3 card-title"
          v-if="productosSolicitadosNuevamenteLista.length == 1"
        >
          Hay {{ productosSolicitadosNuevamenteLista.length }}
          solicitud que contiene algunos de los productos seleccionados
        </div>
        <div
          class="mb-3 card-title"
          v-if="productosSolicitadosNuevamenteLista.length > 1"
        >
          Hay {{ productosSolicitadosNuevamenteLista.length }}
          solicitudes que contienen algunos de los productos seleccionados
        </div>

        <div
          class="mt-3"
          v-for="(solicitud, i) in productosSolicitadosNuevamenteLista"
          :key="'b' + i"
        >
          <h4
            class="card-title mb-3"
            v-if="solicitud.solicitud_correlativo == null"
          >
            Solicitud {{ (i += 1) }}
          </h4>
          <h4
            class="card-title mb-3"
            v-if="solicitud.solicitud_correlativo != null"
          >
            COD Solicitud {{ solicitud.solicitud_correlativo }}
          </h4>

          <div class="table-responsive" id="tabla-productos-anteriores">
            <table class="table mb-0">
              <tbody>
                <tr>
                  <td>Fecha Creación :</td>
                  <td>
                    {{ formatDate(solicitud.fecha_creacion) }}
                  </td>
                </tr>
                <tr>
                  <td>Fecha Entrega :</td>
                  <td>
                    {{ formatDate(solicitud.fecha_entrega) }}
                  </td>
                </tr>
                <tr>
                  <th>Productos</th>
                  <td></td>
                </tr>
                <template v-for="(producto, index) in solicitud.productos">
                  <tr :key="'c' + index">
                    <td>Código :</td>
                    <td>{{ producto.codigo_producto }}</td>
                  </tr>
                  <tr :key="'d' + index">
                    <td>Nombre :</td>
                    <td>{{ producto.nombre_producto }}</td>
                  </tr>
                  <tr :key="'e' + index">
                    <td>Cantidad Solicitada :</td>
                    <td>
                      {{ producto.cantidad_solicitada }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <!-- end table-responsive -->
        </div>
      </div>

      <div class="text-center mt-3">
        <b-button
          variant="light"
          @click="mostrarModalProductoSolicitadoNuevamente = false"
          >Cerrar</b-button
        >
        <b-button
          type="button"
          variant="primary"
          class="btn-md ms-1"
          @click="crearSolicitudMaterialLocal()"
          >Continuar</b-button
        >
      </div>
    </b-modal>
  </v-card>
</template>
<script>
import { required, numeric } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { proyectoMethods, proyectoComputed } from "@/state/helpers";
import {
  lugarInstalacionMethods,
  lugarInstalacionComputed,
} from "@/state/helpers";
import {
  solicitudMaterialMethods,
  solicitudMaterialComputed,
} from "@/state/helpers";
import { productoMethods, productoComputed } from "@/state/helpers";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import { authMethods, authUsuarioComputed } from "@/state/helpers";
import { permiso } from "@/helpers/authservice/obtener-permisos";
import { bodegaMethods, bodegaComputed } from "@/state/helpers";
import SolicitudMaterialDetalle from "./lugarSolicitudMaterial/solicitud-material-detalle.vue";
import SolicitudMaterialDetalleERP from "./lugarSolicitudMaterial/solicitud-material-detalle-erp.vue";
import SolicitudMaterialDespacho from "./lugarSolicitudMaterial/solicitud-material-despacho.vue";
import SolicitudMaterialBuscarProductos from "./lugarSolicitudMaterial/solicitud-material-buscar-productos.vue";
import moment from "moment";
moment.locale("es");
import ReturnMaterials from "./lugarSolicitudMaterial/returnMaterials/index.vue";
import ConsumptionMaterials from "./lugarSolicitudMaterial/consumptionMaterials/index.vue";
import IndexApproveRequests from "./lugarSolicitudMaterial/approveRequests/indexApproveRequests.vue";
import { mapActions } from "vuex";
import JwtService from "@/helpers/authservice/jwt.service";
import {
  REQUEST_MATERIAL,
  REQUEST_MATERIAL_URGENT,
  TYPE_REQUESTS,
} from "@/constants/requestMaterial";
moment.locale("es");
import { read, utils, writeFileXLSX } from "xlsx";
export default {
  name: "LugarSolicitudMaterial",
  mixins: [validationMixin],
  components: {
    Multiselect,
    SolicitudMaterialDetalle,
    SolicitudMaterialDetalleERP,
    SolicitudMaterialDespacho,
    SolicitudMaterialBuscarProductos,
    ReturnMaterials,
    ConsumptionMaterials,
    IndexApproveRequests,
  },
  props: {
    writeable: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(today);
    return {
      //Model V2
      tab: "requests",

      dateMenu: false,
      nombreModulo: "solicitudMateriales",
      bodegasFiltro: [],
      bodegasFiltroCargando: false,
      bloquearControles: false,
      renderProductos: true,
      renderComponent: true,
      loading: false,
      loadingSolicitud: false,
      mensaje: { variant: "", texto: "" },
      segundos: 5,
      segundosMensajeActualizacion: 0,
      segundosMensajeCrearSolicitudModal: 0,
      lugarInstalacion: {},
      proyectos: [],
      totalRows: 1,
      todoTotalRows: 1,
      currentPage: 1,
      todocurrentPage: 1,
      perPage: 10,
      todoperPage: 10,
      pageOptions: [10, 25, 50, 100],
      todoOptions: [10, 50, 100, 150, 200],
      filter: null,
      todoFilter: null,
      filterOn: [],
      todofilterOn: [],
      sortBy: "Proyecto",
      sortDesc: false,
      fields: [
        {
          key: "Accion",
          label: "Acción",
          sortable: false,
        },
        {
          key: "solicitud_correlativo",
          label: "Código Solicitud",
          sortable: true,
        },
        {
          key: "codigo_bodega",
          label: "Código Bodega",
          sortable: true,
        },
        {
          key: "nombre_bodega",
          sortable: true,
        },
        {
          key: "fecha_creacion",
          label: "Fecha Creación",
          sortable: true,
        },
        {
          key: "fecha_entrega",
          sortable: true,
        },
        {
          label: "Tipo de solicitud",
          key: "tipo_documento",
          sortable: true,
        },
        {
          label: "Usuario que solicita",
          key: "nombre_usuario",
          sortable: true,
        },
        {
          key: "nombre_estado_solicitud",
          label: "Estado Solicitud",
          sortable: true,
        },
        {
          key: "tipo_bodega",
          sortable: true,
        },
      ],
      codigoProyecto: null,
      codigoLugarInstalacion: null,
      guardarCerrar: true,
      solicitudSeleccionada: {},
      solicitudMaterial: {
        codigo_lugar_instalacion: "",
        codigo_proyecto: "",
        productos: [],
        tipo_documento: {
          value: 1,
          name: REQUEST_MATERIAL,
        },
      },
      solicitudMateriales: [],
      solicitudMaterialesFiltro: [],
      solicitudMaterialesDetalle: [],
      solicitudMaterialDetalles: [],
      solicitudMaterialDetallesCargando: false,
      esEditar: false,
      submitted: false,
      mostrarModal: false,
      mostrarModalProductos: false,
      productos: [],
      productosCargando: false,
      solicitudMaterialesCargando: false,
      unidades: [
        { value: "un", label: "un" },
        { value: "m", label: "m" },
        { value: "m3", label: "m3" },
        { value: "kg", label: "kg" },
        { value: "ton", label: "ton" },
        { value: "día", label: "día" },
      ],
      tipoSolicitudes: [
        { value: "Solicitud", label: "Solicitud" },
        { value: "Reserva", label: "Reserva" },
        { value: "Recepcion", label: "Recepcion" },
        { value: "Consumo", label: "Consumo" },
        { value: "ValeConsumo", label: "ValeConsumo" },
        { value: "Devolucion", label: "Devolucion" },
      ],
      typeRequests: TYPE_REQUESTS,
      fechaValidar: null,
      fechaTerminoValidar: null,
      form: {
        producto: null,
        cantidadSolicitada: null,
        fechaEntrega: null,
        tipoSolicitudMaterial: {
          value: 1,
          name: REQUEST_MATERIAL,
        },
        dateFormatted: null,
        comentario: null,
        solicitudComentario: null,
        unidad: null,
        bodega: null,
        tipoBodega: null,
      },
      formProducto: {
        producto: null,
        cantidadSolicitada: null,
        comentario: null,
        unidad: null,
      },
      mostrarModalDetalle: false,
      mostrarModalDetalleERP: false,
      mostrarModalSolicitudDespacho: false,
      tipoSolicitud: null,
      rolModuloPermiso: {},
      bodegas: [],
      bodegasCargando: false,
      tipoBodegas: [
        { value: "Central", label: "Central" },
        { value: "Movil", label: "Movil" },
      ],
      mostrarBodegaMovil: false,
      mostrarProductos: false,
      productosFiltrados: [],
      maneraCrearSolicitud: null,
      min: minDate,
      proyectoSeleccionado: {},
      productosSeleccionados: [],
      recargarTabla: true,
      mostrarModalProductoSolicitadoNuevamente: false,
      productosSolicitadosNuevamenteLista: [],
      filtroBodegas: [],
      filtroBodegasSeleccionado: null,
      bodegaMovil: null,
      cantidadMaximaProductos: 100,
      disabledStartDate: {
        to: new Date("12.21.2022"),
        from: new Date("01.01.2020"),
      },
      disabledEndDate: {
        to: new Date("12.21.2022"),
        from: new Date("01.01.1960"),
      },
      tipo_bodega_from_button: "",
      REQUEST_MATERIAL,
      REQUEST_MATERIAL_URGENT,
    };
  },
  validations: {
    form: {
      producto: { required },
      cantidadSolicitada: { required, numeric },
      fechaEntrega: {},
      tipoSolicitudMaterial: {},
      dateFormatted: {},
      comentario: {},
      solicitudComentario: {},
      unidad: { required },
      bodega: {},
      tipoBodega: { required },
    },
    formProducto: {
      producto: { required },
      cantidadSolicitada: { required, numeric },
      fechaEntrega: {},
      comentario: {},
      unidad: { required },
    },
  },
  mounted() {
    if (this.proyectoUsuario == null) {
      this.obtenerProyectoUsuario();
    } else {
      this.proyectoSeleccionado = this.proyectoUsuario;
    }
    this.obtenerBodegasFiltro();
    this.codigoProyecto = this.$route.params.codigoProyecto;
    this.codigoLugarInstalacion = this.$route.params.codigoLugarInstalacion;
    this.lugarInstalacion = {
      codigo_lugar_instalacion: this.codigoLugarInstalacion,
      codigo_proyecto: this.codigoProyecto,
    };
    this.obtenerSolicitudMaterialesProyecto();
    this.solicitudMaterial.codigo_lugar_instalacion = this.codigoLugarInstalacion;
    this.solicitudMaterial.codigo_proyecto = this.codigoProyecto;
    let permisos = permiso.obtenerPermisos(
      this.user,
      //this.$router.currentRoute.name
      this.nombreModulo
    );

    this.rolModuloPermiso = permisos;
    this.obtenerLugarInstalacionLocal();
    this.getApprovedRequestsData();
  },
  methods: {
    ...solicitudMaterialMethods,
    ...productoMethods,
    ...proyectoMethods,
    ...bodegaMethods,
    ...lugarInstalacionMethods,
    ...mapActions({
      createRequestApproveMaterials:
        "requestMaterial/createRequestApproveMaterials",
      getMaterialsRequestsToApprove:
        "requestMaterial/getMaterialsRequestsToApprove",
    }),
    async getApprovedRequestsData() {
      await this.getMaterialsRequestsToApprove({
        user: this.user.email,
        project_code: this.$route.params.codigoProyecto,
      });
    },
    validarNumero(event) {
      if (event.keyCode < 45 || event.keyCode > 57) {
        event.returnValue = false;
      }
      if (event.keyCode == 45) {
        event.returnValue = false;
      }
      if (event.keyCode == 46) {
        event.returnValue = false;
      }
    },
    eliminarFiltrarSolicitudes(item) {
      this.solicitudMaterialesFiltro = this.solicitudMateriales;
    },
    filtrarSolicitudes(item) {
      this.solicitudMaterialesFiltro = [];
      if (item.codigo_bodega == "TODO") {
        this.solicitudMaterialesFiltro = this.solicitudMateriales;
        return;
      }
      this.solicitudMaterialesFiltro.push(
        ...this.solicitudMateriales.filter(
          (val) => val.codigo_bodega == item.codigo_bodega
        )
      );
    },
    obtenerBodegasFiltro() {
      this.bodegasCargando = true;
      this.obtenerBodegas()
        .then((res) => {
          this.bodegasFiltro.unshift({
            codigo_bodega: "BODEGA CENTRAL",
            nombre_bodega: "BODEGA CENTRAL",
          });
          this.bodegasFiltro.unshift({
            codigo_bodega: "TODO",
            nombre_bodega: "TODO",
          });
          res.body.map((item) => {
            this.bodegasFiltro.push(item);
          });

          this.bodegasFiltroCargando = false;
        })
        .catch((error) => {
          this.bodegasFiltroCargando = false;
        });
    },
    agregarProductosSeleccionados(productosSeleccionados) {
      if (this.productosSeleccionados.length == 0) {
        this.productosSeleccionados = productosSeleccionados;
      } else {
        productosSeleccionados.map((producto) => {
          this.productosSeleccionados.push(producto);
        });
      }
      this.mostrarModalProductos = false;

      this.recargarTabla = false;
      // this.solicitudMaterial.productos = this.productosSeleccionados
      this.$nextTick(() => {
        this.recargarTabla = true;
      });
    },
    abrirModalProductos() {
      if (this.solicitudMaterial.tipo_bodega != null) {
        if (this.mostrarBodegaMovil && this.$v.form.bodega.$model == null) {
          return;
        }
        if (this.mostrarBodegaMovil && this.$v.form.bodega.$model != null) {
          this.mostrarModalProductos = true;
        }
        if (this.mostrarBodegaMovil == false) {
          this.mostrarModalProductos = true;
        }
      }
    },
    exportarExcel() {
      this.excel({ codigo_proyecto: this.codigoProyecto })
        .then((res) => {
          let data = utils.json_to_sheet(res.body);
          const workbook = utils.book_new();
          const filename = "Solicitud Materiales";
          utils.book_append_sheet(workbook, data, filename);
          writeFileXLSX(workbook, `${filename}.xlsx`);
        })
        .catch((error) => {});
    },
    obtenerProyectoUsuario() {
      this.obtenerProyecto(this.$route.params.codigoProyecto)
        .then((res) => {
          this.proyectoSeleccionado = res.body;
        })
        .catch((error) => {});
    },
    obtenerLugarInstalacionLocal() {
      this.obtenerLugarInstalacion({
        codigoProyecto: this.codigoProyecto,
        codigoLugarInstalacion: this.codigoLugarInstalacion,
      })
        .then((res) => {
          this.lugarInstalacion = res.body;
        })
        .catch((error) => {});
    },
    cerrarDetalle() {
      this.obtenerSolicitudMaterialesProyecto();
      this.mostrarModalDetalle = false;
    },
    cerrarDetalleERP() {
      this.mostrarModalDetalleERP = false;
    },
    cerrarSolicitudDespacho() {
      this.mostrarModalSolicitudDespacho = false;
    },
    habilitarBodegaMovil(item) {
      if (item.value == "Movil") {
        this.mostrarBodegaMovil = true;
        this.mostrarProductos = false;
        this.solicitudMaterial.tipo_bodega = "Movil";
      } else {
        this.mostrarProductos = true;
        this.mostrarBodegaMovil = false;
        this.solicitudMaterial.tipo_bodega = "Central";
      }
    },
    habilitarProductos(item) {
      this.obtenerStockProductosLocal(item.codigo_bodega);
      this.mostrarProductos = true;
      this.solicitudMaterial.codigo_bodega = item.codigo_bodega;
      this.solicitudMaterial.nombre_bodega = item.nombre_bodega;
    },
    eliminarProducto(producto) {
      var filtrado = this.productosSeleccionados.filter(
        (c) => c.Producto != producto.Producto
      );
      this.productosSeleccionados = filtrado;
    },
    descripcionProducto(producto) {
      return `${producto.Producto} - (${producto.Stock}) - ${producto.Descripción}`;
    },
    actualizarSegundosMensajeActualizacion(segundosMensajeActualizacion) {
      this.segundosMensajeActualizacion = segundosMensajeActualizacion;
    },
    actualizarSegundosMensajeCrearSolicitudModal(
      segundosMensajeCrearSolicitudModal
    ) {
      this.segundosMensajeCrearSolicitudModal = segundosMensajeCrearSolicitudModal;
    },
    abrirModalNuevo(tipoSolicitud, maneraCrearSolicitud) {
      this.bloquearControles = false;
      this.solicitudMaterial.productos = [];
      this.productosSeleccionados = [];
      this.tipoSolicitud = tipoSolicitud;
      this.resetForm();
      this.esEditar = false;
      this.submitted = false;
      this.mostrarModal = true;
      this.fechaValidar = null;
      this.maneraCrearSolicitud = maneraCrearSolicitud;
      this.loadingSolicitud = false;
      this.obtenerBodegasLocal();
      this.mostrarBodegaMovil = false;
      this.mostrarProductos = false;
      if (maneraCrearSolicitud == "rapida") {
        this.mostrarBodegaMovil = true;
        this.mostrarProductos = false;
        this.solicitudMaterial.tipo_bodega = "Movil";
        this.tipo_bodega_from_button = "Movil";
        this.tipoBodegas = [{ value: "Movil", label: "Movil" }];
      } else {
        this.mostrarProductos = true;
        this.mostrarBodegaMovil = false;
        this.solicitudMaterial.tipo_bodega = "Central";
        this.tipo_bodega_from_button = "Central";
        this.tipoBodegas = [{ value: "Central", label: "Central" }];
      }
    },
    abrirModalDetalle(item) {
      this.solicitudSeleccionada = item;
      this.mostrarModalDetalle = true;
    },
    abrirModalDetalleERP(item) {
      this.solicitudSeleccionada = item;
      this.mostrarModalDetalleERP = true;
    },
    abrirModalSolicitudDespacho(item) {
      this.solicitudSeleccionada = item;
      this.mostrarModalSolicitudDespacho = true;
    },
    obtenerBodegasLocal() {
      this.bodegasCargando = true;
      this.obtenerBodegas()
        .then((res) => {
          this.bodegas = res.body;

          this.bodegasCargando = false;
        })
        .catch((error) => {
          this.bodegasCargando = false;
        });
    },
    obtenerStockProductosLocal(filtro) {
      this.productosCargando = true;
      this.obtenerStockProductos()
        .then((res) => {
          this.productos = res.body;

          if (filtro == "Bodega") {
            this.productosFiltrados = this.productos.filter(
              (c) => c.Proyecto == "Bodega"
            );
          } else {
            this.productosFiltrados = this.productos.filter(
              (c) => c.Bodega.toLowerCase() == filtro.toLowerCase()
            );
          }

          this.mostrarProductos = false;
          this.$nextTick(() => {
            this.mostrarProductos = true;
          });

          if (this.esEditar) {
            this.$v.form.producto.$model = {
              producto_id: this.solicitudSeleccionada.producto_id,
              nombre_producto: this.solicitudSeleccionada.nombre_producto,
            };
          }
          this.productosCargando = false;
        })
        .catch((error) => {
          this.productosCargando = false;
        });
    },
    obtenerSolicitudMaterialesProyecto() {
      this.solicitudMaterialesCargando = true;
      this.obtenerSolicitudMaterialesProyectoLugarInstalacion({
        codigoLugarInstalacion: this.codigoLugarInstalacion,
        codigoProyecto: this.codigoProyecto,
      })
        .then((res) => {
          this.solicitudMateriales = res.body;
          this.solicitudMaterialesFiltro = res.body;
          this.totalRows = this.solicitudMaterialesFiltro.length;
          this.solicitudMaterialesCargando = false;
        })
        .catch((error) => {
          this.solicitudMaterialesCargando = false;
        });
    },
    obtenerSolicitudMaterialDetallesProyecto(solicitud_material_id) {
      this.solicitudMaterialDetalles = [];
      this.solicitudMaterialDetallesCargando = true;
      this.obtenerSolicitudMaterialDetalles(solicitud_material_id)
        .then((res) => {
          this.solicitudMaterialDetalles = res.body;
          this.solicitudMaterialDetallesCargando = false;
        })
        .catch((error) => {
          this.solicitudMaterialDetallesCargando = false;
        });
    },
    rechazarSolicitud(item) {
      if (item.rechazada == false) {
        const mensajeRechazar = Swal.mixin({
          customClass: {
            title: "font-size-16",
            confirmButton: "btn btn-primary me-2",
            cancelButton: "btn btn-light",
          },
          buttonsStyling: false,
        });
        mensajeRechazar
          .fire({
            title: "Esta seguro que desea rechazar la solicitud?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, Rechazar",
            cancelButtonText: "Cancelar",
            allowOutsideClick: false,
          })
          .then((result) => {
            if (result.value) {
              this.loading = true;
              this.actualizarSolicitudMaterialRechazada({
                solicitud_material_id: item.solicitud_material_id,
              })
                .then((res) => {
                  if (res.status == 202) {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                      "Ha ocurrido un error, intente nuevamente";
                  }

                  if (res.status == 204) {
                    this.mensaje.variant = "success";
                    this.mensaje.texto = "Solicitud Rechazada correctamente!!!";
                  }
                  this.obtenerSolicitudMaterialesProyecto();
                  this.segundosMensajeActualizacion = this.segundos;
                  this.loading = false;
                })
                .catch((error) => {
                  this.mensaje.variant = "danger";
                  this.mensaje.texto =
                    "Ha ocurrido un error, intente nuevamente";
                  this.obtenerSolicitudMaterialesProyecto();
                  this.segundosMensajeActualizacion = this.segundos;
                  this.loading = false;
                });
            } else {
              this.forceRerender();
            }
          });
      }
    },
    recepcionarSolicitud(item) {
      if (item.recepcionada == false) {
        const mensajeRecepcionar = Swal.mixin({
          customClass: {
            title: "font-size-16",
            confirmButton: "btn btn-primary me-2",
            cancelButton: "btn btn-light",
          },
          buttonsStyling: false,
        });
        mensajeRecepcionar
          .fire({
            title: "Esta seguro que desea recepcionar la solicitud?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, Recepcionar",
            cancelButtonText: "Cancelar",
            allowOutsideClick: false,
          })
          .then((result) => {
            if (result.value) {
              this.loading = true;
              this.actualizarSolicitudMaterialRecepcionada({
                solicitud_material_id: item.solicitud_material_id,
              })
                .then((res) => {
                  if (res.status == 202) {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                      "Ha ocurrido un error, intente nuevamente";
                  }

                  if (res.status == 204) {
                    this.mensaje.variant = "success";
                    this.mensaje.texto =
                      "Solicitud Recepcionada correctamente!!!";
                  }
                  this.obtenerSolicitudMaterialesProyecto();
                  this.segundosMensajeActualizacion = this.segundos;
                  this.loading = false;
                })
                .catch((error) => {
                  this.mensaje.variant = "danger";
                  this.mensaje.texto =
                    "Ha ocurrido un error, intente nuevamente";
                  this.obtenerSolicitudMaterialesProyecto();
                  this.segundosMensajeActualizacion = this.segundos;
                  this.loading = false;
                });
            } else {
              this.forceRerender();
            }
          });
      }
    },
    generarValeConsumoSolicitud(item) {
      if (item.vale_consumo == false) {
        const mensajeValeConsumo = Swal.mixin({
          customClass: {
            title: "font-size-16",
            confirmButton: "btn btn-primary me-2",
            cancelButton: "btn btn-light",
            input: "font-size-16",
            inputLabel: "font-size-16",
          },
          buttonsStyling: false,
        });
        mensajeValeConsumo
          .fire({
            title: "Esta seguro que desea generar el vale de consumo?",
            text: "",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, Generar",
            cancelButtonText: "Cancelar",
            allowOutsideClick: false,
            input: "textarea",
            inputLabel: "Desea agregar un comentario?",
            inputPlaceholder: "Comentario...",
            inputAttributes: {
              maxlength: 500,
            },
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.loading = true;
              this.actualizarSolicitudMaterialValeConsumo({
                solicitud_material_id: item.solicitud_material_id,
                vale_consumo_comentario: result.value,
              })
                .then((res) => {
                  if (res.status == 200) {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                      "Antes de marcar esta opción, debe ingresar todos los productos como consumido (Columna Acción)";
                  }
                  if (res.status == 202) {
                    this.mensaje.variant = "danger";
                    this.mensaje.texto =
                      "Ha ocurrido un error, intente nuevamente";
                  }

                  if (res.status == 204) {
                    this.mensaje.variant = "success";
                    this.mensaje.texto =
                      "Vale de consumo generado correctamente!!!";
                  }
                  this.obtenerSolicitudMaterialesProyecto();
                  this.segundosMensajeActualizacion = this.segundos;
                  this.loading = false;
                })
                .catch((error) => {
                  this.mensaje.variant = "danger";
                  this.mensaje.texto =
                    "Ha ocurrido un error, intente nuevamente";
                  this.obtenerSolicitudMaterialesProyecto();
                  this.segundosMensajeActualizacion = this.segundos;
                  this.loading = false;
                });
            } else {
              this.forceRerender();
            }
          });
      }
    },
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    crearSolicitud() {
      if (this.productosSeleccionados.length == 0) {
        this.mensaje.variant = "danger";
        this.mensaje.texto = "Debe agregar al menos un producto";
        this.segundosMensajeCrearSolicitudModal = this.segundos;
        return;
      }

      if (this.productosSeleccionados.length > this.cantidadMaximaProductos) {
        this.mensaje.variant = "danger";
        this.mensaje.texto = `Solo puede seleccionar hasta ${this.cantidadMaximaProductos} productos`;
        this.segundosMensajeCrearSolicitudModal = this.segundos;
        return;
      }
      if (
        this.maneraCrearSolicitud == "rapida" ||
        this.tipo_bodega_from_button == "Central"
      ) {
        const mensajeEliminar = Swal.mixin({
          customClass: {
            title: "font-size-16",
            htmlContainer: "font-size-13",
            confirmButton: "btn btn-primary me-2",
            cancelButton: "btn btn-light",
          },
          buttonsStyling: false,
        });
        mensajeEliminar
          .fire({
            title: "Esta seguro que desea guardar?",
            text: `Al guardar la ${
              this.form.tipoSolicitudMaterial.name == REQUEST_MATERIAL
                ? "SM"
                : "SMU"
            } generará en base al total, una solicitud de materiales o una solicitud de aprobación`,
            icon: "",
            showCancelButton: true,
            cancelButtonText: "Cancelar",
            confirmButtonText: "Guardar",
            allowOutsideClick: false,
          })
          .then((result) => {
            if (result.value) {
              this.enviarSolicitud();
            }
          });
      } else {
        this.enviarSolicitud();
      }
    },
    enviarSolicitud() {
      let crear = true;
      let self = this;
      this.loadingSolicitud = true;
      this.solicitudMaterial.tipo_solicitud = this.tipoSolicitud;

      this.productosSeleccionados.forEach((producto) => {
        const solicitud_material_id = this.solicitudSeleccionada
          .solicitud_material_id;
        const codigo_proyecto = this.codigoProyecto;
        const codigo_lugar_instalacion = this.codigoLugarInstalacion;
        const codigo_producto = producto.Producto;
        const nombre_producto = producto.Descripción;

        if (producto.cantidad == null || producto.cantidad == 0) {
          this.loadingSolicitud = false;
          crear = false;
          return;
        }
        const cantidad_solicitada = parseInt(producto.cantidad);
        const comentario = producto.comentario;
        const costo = producto.Costo;
        const unidad = null;

        const manera_crear_solicitud = this.maneraCrearSolicitud;
        this.solicitudMaterial.manera_crear_solicitud = manera_crear_solicitud;
        this.solicitudMaterial.codigo_bodega =
          this.$v.form.bodega.$model != null
            ? this.$v.form.bodega.$model.codigo_bodega
            : "BODEGA CENTRAL";
        this.solicitudMaterial.nombre_bodega =
          this.$v.form.bodega.$model != null
            ? this.$v.form.bodega.$model.nombre_bodega
            : "BODEGA CENTRAL";
        // FIXME
        // this.solicitudMaterial.tipo_bodega = this.$v.form.tipoBodega.$model.value;
        this.solicitudMaterial.tipo_bodega = this.tipo_bodega_from_button;
        this.solicitudMaterial.fecha_entrega =
          this.$v.form.fechaEntrega.$model != null
            ? this.$v.form.fechaEntrega.$model
            : moment().format("yyyy-MM-DD");
        this.solicitudMaterial.codigo_bodega_movil = null;
        this.solicitudMaterial.solicitud_comentario =
          this.$v.form.solicitudComentario.$model != null
            ? this.$v.form.solicitudComentario.$model
            : "";

        this.solicitudMaterial.token_usuario = this.user.token_usuario;

        let productoNuevo = {
          codigo_proyecto,
          codigo_lugar_instalacion,
          codigo_producto,
          nombre_producto,
          cantidad_solicitada,
          comentario,
          unidad,
          costo,
        };

        this.solicitudMaterial.productos.push(productoNuevo);

        this.$v.form.comentario.$model = null;

        this.submitted = false;
      });

      if (crear == false) {
        this.mensaje.variant = "danger";
        this.mensaje.texto =
          "Debe ingresar todas las cantidades para los productos";
        this.segundosMensajeCrearSolicitudModal = this.segundos;
        return;
      }
      this.solicitudMaterial.tipo_documento =
        this.form.tipoSolicitudMaterial != null
          ? this.form.tipoSolicitudMaterial.name
          : this.solicitudMaterial.tipo_documento.name;

      this.sendCreateRequestApproveMaterials(false);
    },
    sendCreateRequestApproveMaterials(confirmation) {
      let self = this;
      this.createRequestApproveMaterials({
        params: {
          token: JwtService.getToken(),
        },
        payload: {
          material_request_json: this.solicitudMaterial,
          created_by: this.user.email,
          amount: this.calculateTotalRequestMaterial(),
          project_code: this.codigoProyecto,
          confirmation,
        },
      })
        .then(async (res) => {
          if (res.status == 200) {
            this.mostrarModal = false;
            this.resetFormAdd();
            this.segundosMensajeActualizacion = this.segundos;
            this.obtenerSolicitudMaterialesProyecto();
            this.solicitudMaterial.productos = [];
            await this.getApprovedRequestsData();
            this.loadingSolicitud = false;
            document.getElementById("requestsPending").click();
            setTimeout(() => {
              self.$refs["approve-requests"].openModalListMaterialsRequest(
                res.data
              );
            }, 1000);
          }
          if (res.response && res.response.status == 428) {
            this.loadingSolicitud = false;
            return this.$swal
              .fire({
                icon: "info",
                title: "Atención",
                text:
                  "El proyecto de esta solicitud pertenece a una unidad de negocio distinta a la que perteneces, ¿desea crearla igualmente?",
                confirmButtonText: "Continuar",
                cancelButtonText: "Cancelar",
                showConfirmButton: true,
                showCancelButton: true,
              })
              .then(async (result) => {
                if (result.isConfirmed) {
                  self.loadingSolicitud = true;
                  await self.sendCreateRequestApproveMaterials(true);
                } else {
                  self.loadingSolicitud = false;
                  self.solicitudMaterial.productos = [];
                }
              });
          }
          if (res.status == 208) {
            this.mensaje.variant = "danger";
            this.mensaje.texto = "La solicitud ya existe!!!";
          }
          if (!res.status) {
            this.$swal.fire({
              icon: "error",
              title: "Error",
              text:
                "El usuario que solicita no se encuentra en la lista de empleados",
              position: "top-end",
              showConfirmButton: false,
              toast: true,
              timer: 4000,
              timerProgressBar: true,
            });
          }
          this.segundosMensajeActualizacion = this.segundos;
          this.obtenerSolicitudMaterialesProyecto();
          this.solicitudMaterial.productos = [];
          this.loadingSolicitud = false;
        })
        .catch((error) => {
          console.log(error);
          this.mensaje.variant = "danger";
          this.mensaje.texto = "Ha ocurrido un error, intente nuevamente";
          this.segundosMensajeActualizacion = this.segundos;
          this.loadingSolicitud = false;
        });
    },
    crearSolicitudMaterialLocal() {
      this.mostrarModalProductoSolicitadoNuevamente = false;
    },
    calculateTotalRequestMaterial() {
      return this.solicitudMaterial.productos.reduce(
        (total, currentProduct) => {
          let costProduct =
            currentProduct.cantidad_solicitada * currentProduct.costo;
          return total + costProduct;
        },
        0
      );
    },
    submit(e) {
      this.submitted = true;
      this.$v.form.$touch();

      let validarFechas = false;
      if (
        this.$v.form.fechaEntrega.$model == null &&
        this.tipoSolicitud == "Reserva"
      ) {
        this.fechaValidar = false;
        validarFechas = true;
      }

      if (this.$v.form.$invalid) {
        return;
      }

      if (validarFechas && this.tipoSolicitud == "Reserva") {
        return;
      }
      const solicitud_material_id = this.solicitudSeleccionada
        .solicitud_material_id;

      const codigo_proyecto = this.codigoProyecto;
      const codigo_lugar_instalacion = this.codigoLugarInstalacion;
      const codigo_producto = this.$v.form.producto.$model.Producto;
      const nombre_producto = this.$v.form.producto.$model.Descripción;
      const cantidad_solicitada = this.$v.form.cantidadSolicitada.$model;

      if (
        parseInt(cantidad_solicitada) >
        parseInt(this.$v.form.producto.$model.Stock)
      ) {
        this.mensaje.variant = "danger";
        this.mensaje.texto =
          "La cantidad solicitda es mayor a la cantidad disponible";
        this.segundosMensajeCrearSolicitudModal = this.segundos;
        return;
      }

      const comentario = this.$v.form.comentario.$model;
      const unidad = this.$v.form.unidad.$model.value;

      const manera_crear_solicitud = this.maneraCrearSolicitud;
      this.solicitudMaterial.manera_crear_solicitud = manera_crear_solicitud;
      this.solicitudMaterial.codigo_bodega =
        this.$v.form.bodega.$model != null
          ? this.$v.form.bodega.$model.codigo_bodega
          : "BODEGA CENTRAL";
      this.solicitudMaterial.nombre_bodega =
        this.$v.form.bodega.$model != null
          ? this.$v.form.bodega.$model.nombre_bodega
          : "BODEGA CENTRAL";
      this.solicitudMaterial.tipo_bodega = this.$v.form.tipoBodega.$model.value;
      this.solicitudMaterial.fecha_entrega =
        this.$v.form.fechaEntrega.$model != null
          ? this.$v.form.fechaEntrega.$model
          : null;
      this.solicitudMaterial.codigo_bodega_movil = null;
      this.solicitudMaterial.solicitud_comentario =
        this.$v.form.solicitudComentario.$model != null
          ? this.$v.form.solicitudComentario.$model
          : "";

      let producto = {
        codigo_proyecto,
        codigo_lugar_instalacion,
        codigo_producto,
        nombre_producto,
        cantidad_solicitada,
        comentario,
        unidad,
      };

      this.solicitudMaterial.productos.push(producto);

      this.$v.form.producto.$model = null;
      this.$v.form.cantidadSolicitada.$model = null;
      this.$v.form.comentario.$model = null;
      this.$v.form.unidad.$model = null;
      this.bloquearControles = true;

      this.submitted = false;
    },
    resetForm() {
      this.form = {
        fechaEntrega: null,
        comentario: null,
        bodega: null,
        tipoBodega: null,
        tipoSolicitudMaterial: {
          value: 1,
          name: REQUEST_MATERIAL,
        },
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    resetFormProducto() {
      this.formProducto = {
        producto: null,
        cantidadSolicitada: null,
        comentario: null,
        unidad: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    resetFormAdd() {
      this.form = {
        producto: null,
        cantidadSolicitada: null,
        comentario: null,
        unidad: null,
      };
    },
    resetFormProductoAdd() {
      this.formProductoAdd = {
        producto: null,
        cantidadSolicitada: null,
        comentario: null,
        unidad: null,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    eliminarActividadProyectoLocal(item) {
      const mensajeEliminar = Swal.mixin({
        customClass: {
          title: "font-size-18",
          confirmButton: "btn btn-danger ms-2",
          cancelButton: "btn btn-gris-claro",
        },
        buttonsStyling: false,
      });
      mensajeEliminar
        .fire({
          title: "Esta seguro que desea eliminar el registro?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Eliminar",
          cancelButtonText: "Cancelar",
          allowOutsideClick: false,
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            this.eliminarActividadProyecto(item.solicitud_material_id)
              .then((res) => {
                if (res.status == 202) {
                  this.mensaje.variant = "danger";
                  this.mensaje.texto =
                    "Ha ocurrido un error, intente nuevamente";
                }

                if (res.status == 204) {
                  this.mensaje.variant = "success";
                  this.mensaje.texto = "Solicitud eliminada correctamente!!!";
                  if (this.guardarCerrar) {
                    this.mostrarModal = false;
                  }
                }
                this.segundosMensajeActualizacion = this.segundos;
                this.obtenerSolicitudMaterialesProyecto();
              })
              .catch((error) => {
                this.mensaje.variant = "danger";
                this.mensaje.texto =
                  "No se ha eliminado el registro, intente nuevamente";
                this.mostrarModal = false;
                this.segundosMensajeActualizacion = this.segundos;
              });
          }
        });
    },
    colorTipoSolicitud(item) {
      if (item == "Solicitud") {
        return "bg-success";
      } else if (item == "Reserva") {
        return "bg-primary";
      } else if (item == "Recepcionada") {
        return "bg-warning";
      } else if (item == "Consumida") {
        return "bg-info";
      } else if (item == "ValeConsumo") {
        return "bg-info";
      } else if (item == "Devolucion") {
        return "bg-danger";
      } else if (item == "Central") {
        return "bg-success";
      } else if (item == "Movil") {
        return "bg-primary";
      } else if (item == "Abierta") {
        return "bg-success";
      } else if (item == "Cerrada") {
        return "bg-danger";
      }
    },
    irLugarinstalacion(proyecto) {
      this.seleccionarProyecto(proyecto[0]);
      this.$router.push({
        name: "lugaresInstalacion",
        params: { codigoProyecto: proyecto[0].codigo_proyecto },
      });
    },
    formatDate(value) {
      const duration = moment(value).format("DD/MM/yyyy");
      return `${duration}`;
    },
    formatDateTime(value) {
      const duration = moment(value).format("DD/MM/yyyy HH:mm:ss");
      return `${duration}`;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    todoFiltered(filteredItems) {
      this.todoTotalRows = filteredItems.length;
      this.todocurrentPage = 1;
    },

    handleInput(value, data) {
      this.editableDataItems[data.index][data.field.key] = value;
    },
  },
  computed: {
    ...proyectoComputed,
    ...authUsuarioComputed,
  },
  watch: {
    "form.fechaEntrega": function(date) {
      this.form.dateFormatted = date ? this.formatDate(date) : null;
    },
  },
};
</script>

<style>
.v-application--wrap {
  min-height: 100px;
}
</style>
<style scoped>
.table-responsive-sm {
  overflow: auto !important;
}
</style>
<style>
.crearActividadesProyecto {
  margin-right: 10px;
}

.table-solicitud-material-detalle {
  height: 200px;
}

.botones-menu {
  width: 150px;
  margin-right: 10px;
  display: inline-block;
}
.subtitulo {
  margin-top: 15px;
}
@media (max-width: 576px) {
  .botones-menu {
    width: 100%;
    margin-right: 0px;
    margin: 3px;
  }
}

.contenedor-producto-anterior {
  height: 500px;
  overflow-y: scroll;
}
</style>
